export enum AvgMarketSavingsInterestRate {
  LowBalanceRate = 0.14,
  MidBalanceRate = 0.17,
  HighBalanceRate = 0.19,
  XtraHighBalanceRate = 0.22,
}

export enum AvgMarketTermInterestRate {
  Months3Rate = 0.75,
  Months6Rate = 0.9,
  Months12Rate = 1.05,
}

export enum AudurStaticInterestRates {
  SavingsAccountInterestRate = 1.0,
  TermAccountInterestRate3month = 1.35,
  TermAccountInterestRate6month = 1.55,
  TermAccountInterestRate12month = 1.75,
}

export const MaxAmountOutsideRBBusinessHours = 10000000;
