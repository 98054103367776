import styled from 'styled-components';
import { FontSizeRem } from '../../styles/Styleguide';

export const StyledHeading = styled.h1`
  font-size: ${FontSizeRem.XL};
  margin: 0;

  @media all and (max-width: 25em) {
    font-size: ${FontSizeRem.H1};
  }
`;

export const StyledSpan = styled.span`
  display: inline;

  @media all and (max-width: 20em) {
    display: none;
  }
`;

export const StyledMobileSpan = styled.span`
  display: none;

  @media all and (max-width: 20em) {
    display: inline;
  }
`;
