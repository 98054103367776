import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { logOut, useAppContext } from '../../store/AppContext';
import { getSessionStorageItem, SessionStorageKeys } from '../browserStorage/SessionStorage';
import { loggedOutNavLinks, Route } from '../navigation/navigation';

const checkSessionExpiration = () => {
  const userSession = getSessionStorageItem(SessionStorageKeys.AUDUR_USER);
  const accessSession = getSessionStorageItem(SessionStorageKeys.USER_ACCESS);

  // If both user and access sessions are undefined, session is expired
  return userSession === undefined && accessSession === undefined;
};

const useSessionExpirationCheck = () => {
  const router = useRouter();
  const { dispatch } = useAppContext();

  useEffect(() => {
    const interval = setInterval(() => {
      if (checkSessionExpiration()) {
        if (!loggedOutNavLinks.includes(router.pathname as Route)) {
          logOut(dispatch, router);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch, router]);
};

export default useSessionExpirationCheck;
