import { CSSProperties } from 'react';

import { StyledButton } from './ClickableButtonStyles';

type Props = {
  children: JSX.Element;
  style?: CSSProperties;
  onClick?: () => void;
  className?: string;
};

const ClickableButton = ({ children, style, onClick, className }: Props) => {
  return (
    <StyledButton style={style} onClick={onClick} className={className}>
      {children}
    </StyledButton>
  );
};
export default ClickableButton;
