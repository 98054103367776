import { ProductType } from '@kvika/audur-api-types';

export interface IYearInterestSummary {
  [key: string]: number;
}

export type MonthlyInterest = {
  monthIndex: number;
  monthDisplayName: string;
  interest: number;
};

export enum Term {
  Months3 = 'months_3',
  Months6 = 'months_6',
  Months12 = 'months_12',
}

export enum TransactionErrorMessage {
  ACCOUNT_NOT_AVAILABLE = 'ACCOUNT_NOT_AVAILABLE',
  NOT_ENOUGH_BALANCE = 'NOT_ENOUGH_BALANCE',
  ACCOUNT_NOT_OWNED = 'ACCOUNT_NOT_OWNED',
  TRANSACTION_ON_CREATION_DAY = 'TRANSACTION_ON_CREATION_DAY',
  TERM_DEPOSIT_CREATION_OUTSIDE_HOURS = 'TERM_DEPOSIT_CREATION_OUTSIDE_HOURS',
  RTGS_SYSTEM_CLOSED = 'RTGS_SYSTEM_CLOSED',
  DEPOSIT_ACCOUNT_LOCKED = 'DEPOSIT_ACCOUNT_LOCKED',
  WITHDRAWAL_LIMIT_OUTSIDE_HOURS_EXCEEDED = 'WITHDRAWAL_LIMIT_OUTSIDE_HOURS_EXCEEDED',
  WITHDRAWAL_ACCOUNT_INSUFFICIENT_FUNDS = 'WITHDRAWAL_ACCOUNT_INSUFFICIENT_FUNDS',
}

export enum TransactionErrorMessageIcelandic {
  ACCOUNT_NOT_AVAILABLE = 'Reikningur ekki til.',
  NOT_ENOUGH_BALANCE = 'Ekki næg ráðstöfun á úttektarreikningi',
  ACCOUNT_NOT_OWNED = 'Þessi reikingur er ekki í þinni eigu',
  TRANSACTION_ON_CREATION_DAY = 'Úttekt ekki leyfð sama dag og reikningur er stofnaður',
  TERM_DEPOSIT_CREATION_OUTSIDE_HOURS = 'Ekki er hægt að búa til nýja bundna reikninga á milli 21:00 og 00:00',
  RTGS_SYSTEM_CLOSED = 'Millifærsla tókst ekki. Stórgreiðslukerfi Seðlabankans lokar 16:30 og opnar aftur klukkan 09:00 næsta virka dag.',
  DEPOSIT_ACCOUNT_LOCKED = 'Ráðstöfunarreikningur þinn er læstur fyrir innborganir. Þú þarft að fá viðskiptabanka þinn til þess að opna reikninginn eða skipta um ráðstöfunarreikning undir stillingar.',
  WITHDRAWAL_LIMIT_OUTSIDE_HOURS_EXCEEDED = 'Ekki er hægt að búa til nýja bundna reikninga á milli 21:00 og 00:00.',
  WITHDRAWAL_ACCOUNT_INSUFFICIENT_FUNDS = 'Ekki næg innstæða á úttektarreikningi. Athugaðu að fyrri millifærslur gætu verið í bið.',
}

export enum ServerErrorMessage {
  INTERNAL_SERVER_ERROR = 'Internal server error',
  AUTHENTICATION_FAILED = 'Authentication failed',
  AUTH_ERROR = "Sorry, you're not authorized to access this service",
  YOU_ARE_NOT_AUTHORIZED = 'You are not authorized',
  POWER_OF_ATTORNEY_ERROR = 'Not able to sign attorney of power document',
  SOPRA_MIGRATION = 'Banking system is being migrated',
  INVALID_PHONE_NUMBER = 'Invalid phone number',
  REGISTRATION_CANT_BE_COMPLETED = "Registration can't be completed without a phone number",
}

export enum ServerErrorCode {
  AUTH_ERROR__AUDKENNI_FAILURE = 17,
  AUTH_ERROR__YOUNGER_THAN_MINIMUM_REGISTRATION_AGE = 18,
  AUTH_ERROR_ALREADY_PENDING_LOGIN_REQUEST = 23,
  DOCUMENTS_DOCUMENT_ALREADY_SIGNED = 'DOCUMENTS_DOCUMENT_ALREADY_SIGNED',
  USERS_ACCESS_ALREADY_ACTIVE = 'USERS_ACCESS_ALREADY_ACTIVE',
  SERVER_TIMEOUT = 504,
}

export enum ErrorHeadingsIcelandic {
  DEFAULT = 'Eitthvað fór úrskeiðis',
}

export enum ServerErrorMessageIcelandic {
  AUTHENTICATION_FAILED = 'Ekki tókst að auðkenna notanda.',
  AUTH_ERROR_USER_CANCELLATION = 'Auðkenning mistókst, notandi hætti við',
  YOU_ARE_NOT_AUTHORIZED = 'Auðkenni rann út. Vinsamlegast skráðu þig aftur inn.',
  POWER_OF_ATTORNEY_ERROR = 'Ekki tókst að undirrita umboð.',
  SOPRA_MIGRATION = 'Vegna kerfisuppfærslu er ekki hægt að millifæra né stofna reikning hjá Auði þessa helgi.',
  INVALID_PHONE_NUMBER = 'Ógilt símanúmer. Vinsamlegast reyndu aftur.',
  REGISTRATION_CANT_BE_COMPLETED = 'Nýskráning tókst ekki. Verið er að vinna að því að heimila nýskráningu með Auðkennisappi. Við biðjumst afsökunar á þessu.',
}

export enum ChildrensProductTypes {
  ChildrensSavings = 'ChildrensSavingsAccount',
  ChildrensTermDeposit = 'ChildrensTermDeposit',
  ChildrensIndexed = 'ChildrensIndexedSavingsAccount',
}

export enum ExtraSavingsAccounts {
  ExtraSavingsAccounts = 'ExtraSavingsAccounts',
}

// TODO: Get rid of and use utils from kvika-ui?
export type ProductTypes = ProductType | ChildrensProductTypes | ExtraSavingsAccounts;
