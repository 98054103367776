const SwitchUserSvg = () => (
  <svg width={24} height={20} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M0 18.84C0 14.9929 2.07913 11.8762 5.93348 11.8762H9.51C10.1948 11.8762 10.8522 11.9738 11.4783 12.1574V14.1415C10.8717 13.8876 10.2104 13.7509 9.51391 13.7509H5.93739C3.36652 13.7509 2.2813 15.653 1.93304 18.1253H12.5935C13.0748 18.8595 13.6891 19.4962 14.4052 20H1.16217C0.520435 20 0 19.4805 0 18.84ZM2.71304 5.0022C2.71304 3.2173 3.66783 1.5652 5.21739 0.6708C6.76696 -0.2236 8.67652 -0.2236 10.2261 0.6708C11.7757 1.5652 12.7304 3.2173 12.7304 5.0022C12.7304 6.78709 11.7757 8.43919 10.2261 9.33359C8.67652 10.228 6.76696 10.228 5.21739 9.33359C3.66783 8.43919 2.71304 6.78709 2.71304 5.0022ZM4.5913 5.0022C4.5913 6.11922 5.18609 7.15032 6.15652 7.70883C7.12696 8.26734 8.31652 8.26734 9.28696 7.70883C10.2574 7.15032 10.8522 6.11922 10.8522 5.0022C10.8522 3.88517 10.2574 2.85407 9.28696 2.29556C8.31652 1.73705 7.12696 1.73705 6.15652 2.29556C5.18609 2.85407 4.5913 3.88517 4.5913 5.0022ZM12.7304 9.68901C12.7304 9.16955 13.1491 8.75165 13.6696 8.75165C14.19 8.75165 14.6087 9.16955 14.6087 9.68901V10.185C15.6065 9.29454 16.9213 8.75165 18.3652 8.75165C20.6778 8.75165 22.6617 10.1421 23.5304 12.1262C23.7378 12.5987 23.5187 13.1533 23.0452 13.3603C22.5717 13.5673 22.0161 13.3486 21.8087 12.876C21.2296 11.552 19.903 10.6264 18.3652 10.6264C17.25 10.6264 16.2522 11.1068 15.5635 11.8762H16.8C17.3204 11.8762 17.7391 12.2941 17.7391 12.8136C17.7391 13.333 17.3204 13.7509 16.8 13.7509H13.6696C13.1491 13.7509 12.7304 13.333 12.7304 12.8136V9.68901ZM13.2 16.6255C12.9926 16.1529 13.2117 15.5983 13.6852 15.3913C14.1587 15.1843 14.7143 15.403 14.9217 15.8756C15.5009 17.1996 16.8274 18.1253 18.3652 18.1253C19.4804 18.1253 20.4783 17.6449 21.167 16.8755H19.9304C19.41 16.8755 18.9913 16.4576 18.9913 15.9381C18.9913 15.4186 19.41 15.0007 19.9304 15.0007H23.0609C23.5813 15.0007 24 15.4186 24 15.9381V19.0626C24 19.5821 23.5813 20 23.0609 20C22.5404 20 22.1217 19.5821 22.1217 19.0626V18.5666C21.1239 19.4571 19.8091 20 18.3652 20C16.0526 20 14.0687 18.6096 13.2 16.6255Z"
      fill="#F5F5F5"
    />
  </svg>
);
export default SwitchUserSvg;
