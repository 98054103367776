import { ReactNode, useState } from 'react';
import { DocumentSignatureSchema, SignatureStatus } from '@kvika/audur-api-types';
import { ErrorStrings } from 'utils/strings';
import { useAppContext } from 'store/AppContext';
import { DocumentSigningContext } from 'store/DocumentSigningContext';
import { trackEvent, SegmentTrackingId } from 'utils/AudurAnalytics';

type Props = {
  children?: ReactNode | ReactNode[];
};

const DocumentSigningHandler = ({ children }: Props) => {
  const { state } = useAppContext();
  const { apiClient } = state;
  const [firstTime, setFirstTime] = useState<number>(0);
  const [secondTime, setSecondTime] = useState<number>(1000);

  const postDocumentSignature = (documentId: number) => {
    return apiClient.getAudurWebApiClient().postDocumentSignature(documentId);
  };

  const getDocumentSigningStatus = (documentId: number, signatureId: number) => {
    return apiClient.getAudurWebApiClient().getDocumentSignatureStatus(documentId, signatureId);
  };

  const handleDocumentSigningStatus = (
    response: DocumentSignatureSchema,
    signatureId: number,
    setVerificationCode: (code: string) => void,
    handleDocumentStatusError: (errorText: string) => void,
    handleDocumentStatusSigned: () => void,
    onGetDocumentStatusRetry: (signatureId: number, finalPollingTime: Date) => void,
    finalPollingTime: Date
  ) => {
    switch (response.status) {
      // User declined or ignored the signing of the document and we display an error
      case SignatureStatus.Declined:
      case SignatureStatus.Ignored: {
        handleDocumentStatusError(ErrorStrings.SignDocumentCancellationError);
        break;
      }
      // User signed the document and we continue with the registration flow
      case SignatureStatus.Signed: {
        trackEvent({ event: SegmentTrackingId.RegistrationDocumentSignCompleted });
        handleDocumentStatusSigned();
        break;
      }
      // User waited to long and the requrest expired and we display an error
      case SignatureStatus.Expired: {
        handleDocumentStatusError(ErrorStrings.SignDocumentTimeOutError);
        break;
      }
      // Otherwise we call the get endpoint again and check the status of the signature
      default: {
        response.code && setVerificationCode(response.code);
        // Add this check so that we are not checking the document signing status infinitaly,
        // stop if 120 seconds have passed since the first request
        if (finalPollingTime > new Date()) {
          const timeToWait = firstTime + secondTime;
          setTimeout(() => {
            // We do this to poll the api incrementally using fibonacci
            setFirstTime(secondTime);
            setSecondTime(timeToWait);
            onGetDocumentStatusRetry(signatureId, finalPollingTime);
          }, timeToWait);
        } else {
          handleDocumentStatusError(ErrorStrings.SignDocumentTimeOutError);
        }
        break;
      }
    }
  };

  return (
    <DocumentSigningContext.Provider
      value={{
        postDocumentSignature,
        getDocumentSigningStatus,
        handleDocumentSigningStatus,
      }}
    >
      {children}
    </DocumentSigningContext.Provider>
  );
};
export default DocumentSigningHandler;
