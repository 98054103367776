import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponseHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import {
  LoginResponseSchema,
  RegisterSchema,
  RegisterResponseSchema,
  CountrySchema,
  AccountNumberSchema,
  ValidateAccountResponseSchema,
  AccountSchema,
  CreateTermDepositAccountSchema,
  UpdateUsersProfileResponseSchema,
  UpdateUsersProfileSchema,
  ProductSchema,
  TransactionSchema,
  CreateTransactionSchema,
  GetUsersProfileResponseSchema,
  PushType,
  ChildSchema,
  CreateFutureAccountSchema,
  SessionSchema,
  CreateChildInsuranceQuoteSchema,
  CreateChildInsuranceQuoteResponseSchema,
  GetInsuranceStatusResponseSchema,
  CheckoutChildInsuranceQuoteSchema,
  CheckoutChildInsuranceQuoteResponseSchema,
  AddressWriteSchema,
  AddressSchema,
  ProductInterestRatesSchema,
  DocumentSignatureSchema,
  LoginInitSchema,
  KSAuthLoginInitResponseSchema,
  LoginVerifySchema,
  CreateTransactionResponseSchema,
  CreateSavingsAccountSchema,
  UpdateSavingsAccountSchema,
  CreateIndexedSavingsAccountSchema,
  NoticeSchema,
  CreateNoticeSchema,
  UpdateIndexedSavingsAccountSchema,
  SearchUsersSchema,
  SharedAccountRequestSchema,
  CreateSharedAccountRequest,
  SharedAccountsSummaryResponse,
  UpdateAccountSchema,
  UpdateUserAccessResponse,
  RegisterCompanyResponse,
  RegisterCompanySchema,
  UserAccessSchema,
  AudurCompanySchema,
  UpdateAudurCompanyRequest,
} from '@kvika/audur-api-types';
import AudurApiError, { AudurAxiosError } from './ApiError';
import { ErrorDescription } from './Constants';

type IStoreAuthToken = (token: string) => void;

type ClientConfig = {
  apiUrl: string;
  storeAuthToken: IStoreAuthToken;
  authToken?: string | null;
  onResponseHeaders?: (header: AxiosResponseHeaders) => void;
  apiVersion?: string;
};

enum StatusCode {
  Ok = 200,
  Created = 201,
}
export interface IAML {
  AML: {
    SALARY: string;
    BORROWED: string;
    INHERITANCE: string;
    CAPITAL_GAIN: string;
    OTHER: string;
  };
}

export interface InterestSummary {
  [key: string]: number;
}

class AudurClient {
  private axiosInstance: AxiosInstance;

  private requestConfig: AxiosRequestConfig = {
    baseURL: '',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  storeAuthToken: IStoreAuthToken;

  constructor({ apiUrl, storeAuthToken, authToken, onResponseHeaders, apiVersion }: ClientConfig) {
    this.axiosInstance = axios.create({ ...this.requestConfig, baseURL: apiUrl });
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (onResponseHeaders) {
          onResponseHeaders(response.headers as AxiosResponseHeaders);
        }
        return response;
      },
      (error) => {
        if (onResponseHeaders && error?.response?.headers) {
          onResponseHeaders(error.response.headers);
        }
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      const conf = config;
      if (conf.headers) {
        if (authToken) {
          conf.headers.Authorization = `Bearer ${authToken}`;
        }
        if (apiVersion) {
          conf.headers['X-API-VERSION'] = apiVersion;
        }
      }
      return conf;
    });
    this.storeAuthToken = storeAuthToken;
  }

  /**
   * Logout
   */
  public async logout(): Promise<number> {
    try {
      const response = await this.axiosInstance.delete('/users/logout');
      delete this.axiosInstance.defaults.headers.common.Authorization;
      if (response.status === StatusCode.Ok) {
        return Promise.resolve(response.status);
      }

      throw new Error(ErrorDescription.LogoutStatusError);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.LogoutError, error as AudurAxiosError);
    }
  }

  public async register(registerScheme: RegisterSchema): Promise<RegisterResponseSchema> {
    try {
      const response = await this.axiosInstance.post<RegisterResponseSchema>('/users/register', registerScheme);

      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.RegisterUserError, error as AudurAxiosError);
    }
  }

  public async getUserProfile(): Promise<GetUsersProfileResponseSchema> {
    try {
      const response = await this.axiosInstance.get<GetUsersProfileResponseSchema>('/users/profile');
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.UserProfileError, error as AudurAxiosError);
    }
  }

  public async updateUserProfile(profileSchema: UpdateUsersProfileSchema): Promise<UpdateUsersProfileResponseSchema> {
    try {
      const response = await this.axiosInstance.put<UpdateUsersProfileResponseSchema>('/users/profile', profileSchema);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.UpdateUserError, error as AudurAxiosError);
    }
  }

  /**
   * Countries endpoints
   */
  public async getCountries(): Promise<CountrySchema[]> {
    try {
      const response = await this.axiosInstance.get<CountrySchema[]>('/countries');
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.CountriesError, error as AudurAxiosError);
    }
  }

  /**
   * Accounts endpoints
   */
  public async createTermDepositAccount(account: CreateTermDepositAccountSchema): Promise<AccountSchema> {
    try {
      const response = await this.axiosInstance.post<AccountSchema>('/accounts/term_deposit', account);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.CreateTermDepositAccountError, error as AudurAxiosError);
    }
  }

  public async getAccounts(): Promise<AccountSchema[]> {
    try {
      const response = await this.axiosInstance.get<AccountSchema[]>('/accounts?status=open');
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetAccountsError, error as AudurAxiosError);
    }
  }

  public async getClosedAndDeletedAccounts(): Promise<AccountSchema[]> {
    try {
      const response = await this.axiosInstance.get<AccountSchema[]>('/accounts?status=deleted&status=closed');
      return response.data;
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetClosedAndDeletedAccounts, error as AudurAxiosError);
    }
  }

  public async getInterestSummary(): Promise<InterestSummary> {
    try {
      const response = await this.axiosInstance.get<InterestSummary>('/accounts/interest_summary');
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.InterestSummaryError, error as AudurAxiosError);
    }
  }

  public async validateAccount(accountNumber: AccountNumberSchema): Promise<ValidateAccountResponseSchema> {
    try {
      const response = await this.axiosInstance.get<ValidateAccountResponseSchema>('/accounts/validate', {
        params: {
          bank: accountNumber.bank,
          branch: accountNumber.branch,
          account: accountNumber.account,
        },
      });
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.AccountValidationError, error as AudurAxiosError);
    }
  }

  /**
   * Debug endpoints
   */
  public async debugResetUser(): Promise<number> {
    try {
      const response = await this.axiosInstance.delete('/debug/reset_user');
      if (response.status === StatusCode.Ok) {
        return Promise.resolve(response.status);
      }

      throw new Error(ErrorDescription.ResetUserStatusCodeError);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.ResetUserError, error as AudurAxiosError);
    }
  }

  public async debugResetCompany(ssn: string): Promise<string> {
    try {
      const response = await this.axiosInstance.delete<string>(`/debug/reset_company/${ssn}`);
      if (response.status === StatusCode.Ok) {
        return Promise.resolve(response.data);
      }

      throw new Error(ErrorDescription.ResetCompanyStatusCodeError);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.ResetCompanyError, error as AudurAxiosError);
    }
  }

  public async debugPushNotification(pushType: PushType): Promise<number> {
    try {
      const response = await this.axiosInstance.post('/debug/push', null, { params: { pushType } });
      if (response.status === StatusCode.Ok) {
        return Promise.resolve(response.status);
      }

      throw new Error(ErrorDescription.DebugPushNotificationError);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.DebugPushNotificationError, error as AudurAxiosError);
    }
  }

  public async debugResetChildInsurance(): Promise<number> {
    try {
      const response = await this.axiosInstance.delete('/debug/reset_child_insurance');
      if (response.status === StatusCode.Ok) {
        return Promise.resolve(response.status);
      }
      throw new Error(ErrorDescription.ResetChildInsuranceStatusCodeError);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.ResetChildInsuranceError, error as AudurAxiosError);
    }
  }

  public async debugResetRecurringAML(): Promise<number> {
    try {
      const response = await this.axiosInstance.put('/debug/set_recurring_aml');
      if (response.status === StatusCode.Ok) {
        return Promise.resolve(response.data);
      }
      throw new Error(ErrorDescription.ResetRecurringAMLStatusCodeError);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.ResetRecurringAMLError, error as AudurAxiosError);
    }
  }

  /**
   * Products endpoints
   */
  public async getProducts(): Promise<ProductSchema[]> {
    try {
      const response = await this.axiosInstance.get<ProductSchema[]>('/products');
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetProductsError, error as AudurAxiosError);
    }
  }

  /**
   * Transactions endpoints
   */
  public async getTransactions(accountId: number): Promise<TransactionSchema[]> {
    try {
      const response = await this.axiosInstance.get<TransactionSchema[]>(`/transactions/${accountId}`);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetTransactionsError, error as AudurAxiosError);
    }
  }

  /**
   * Transfers money to another account
   * 1) If no destinationAccountId is provided, the money will be transferred to the user's external account
   * 2) If a destinationAccountId is provided, the money will be transferred to that account
   * The destinationAccountId only refers to the user's Audur accounts and currently only works for future accounts.
   * @param createTransactionSchema
   */
  public async transferMoney(
    createTransactionSchema: CreateTransactionSchema
  ): Promise<CreateTransactionResponseSchema> {
    try {
      const response = await this.axiosInstance.post<CreateTransactionResponseSchema>(
        '/transactions',
        createTransactionSchema
      );
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.TransferError, error as AudurAxiosError);
    }
  }

  /**
   * Makes a debug deposit to staging accounts
   */
  public async doDeposit(): Promise<number> {
    try {
      const response = await this.axiosInstance.post('/debug/do_deposit');
      if (response.status === StatusCode.Ok) {
        return Promise.resolve(response.status);
      }

      throw new Error(ErrorDescription.DebugDepositStatusCodeError);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.DebugDepositError, error as AudurAxiosError);
    }
  }

  /**
   * Gets an account by id
   * @param accountId
   */
  public async getAccountById(accountId: number): Promise<AccountSchema> {
    try {
      const response = await this.axiosInstance.get<AccountSchema>(`/accounts/${accountId}`);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetAccountByIdError, error as AudurAxiosError);
    }
  }

  /**
   * Gets user's children according to RB / the National Registry
   */
  public async getUsersChildren(): Promise<ChildSchema[]> {
    try {
      const response = await this.axiosInstance.get<ChildSchema[]>('/users/children');
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.UsersChildrenError, error as AudurAxiosError);
    }
  }

  public async createFutureAccount(requestBody: CreateFutureAccountSchema) {
    try {
      const response = await this.axiosInstance.post<AccountSchema>('/accounts/future', requestBody);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.CreateFutureAccountError, error as AudurAxiosError);
    }
  }

  public async getUsersCurrentSession(): Promise<SessionSchema> {
    try {
      const response = await this.axiosInstance.get<SessionSchema>('/users/sessions/current');
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetUsersCurrentSessionError, error as AudurAxiosError);
    }
  }

  public async createChildInsuranceQuotePackage(
    requestBody: CreateChildInsuranceQuoteSchema
  ): Promise<CreateChildInsuranceQuoteResponseSchema> {
    try {
      const response = await this.axiosInstance.post<CreateChildInsuranceQuoteResponseSchema>(
        '/insurance/child',
        requestBody
      );
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.CreateChildInsuranceQuotePackageError, error as AudurAxiosError);
    }
  }

  public async checkoutChildInsuranceQuotePackage(
    requestBody: CheckoutChildInsuranceQuoteSchema
  ): Promise<CheckoutChildInsuranceQuoteResponseSchema> {
    try {
      const response = await this.axiosInstance.post<CheckoutChildInsuranceQuoteResponseSchema>(
        '/insurance/child/checkout',
        requestBody
      );
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.CheckoutChildInsuranceQuotePackageError, error as AudurAxiosError);
    }
  }

  public async getChildInsuranceStatus(ssn: string): Promise<GetInsuranceStatusResponseSchema> {
    try {
      const response = await this.axiosInstance.get<GetInsuranceStatusResponseSchema>(`/insurance/child/${ssn}/status`);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetChildInsuranceStatusError, error as AudurAxiosError);
    }
  }

  public async updateUserAddress(addressSchema: AddressWriteSchema): Promise<AddressSchema> {
    try {
      const response = await this.axiosInstance.put<AddressSchema>('/users/addresses', addressSchema);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.UpdateAddressError, error as AudurAxiosError);
    }
  }

  public async getHistoricalInterestRates(): Promise<ProductInterestRatesSchema[]> {
    try {
      const response = await this.axiosInstance.get<ProductInterestRatesSchema[]>('/products/interest_rates');
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetHistoricalInterestRatesError, error as AudurAxiosError);
    }
  }

  public async postStartLogin(data: LoginInitSchema): Promise<KSAuthLoginInitResponseSchema> {
    try {
      const response = await this.axiosInstance.post<KSAuthLoginInitResponseSchema>('/users/login/init', data);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.PostStartLoginError, error as AudurAxiosError);
    }
  }

  public async putLoginStatus(data: LoginVerifySchema): Promise<LoginResponseSchema> {
    try {
      const response = await this.axiosInstance.put<LoginResponseSchema>('/users/login/verify', data);
      this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${response.data.authorizationToken}`;
      this.storeAuthToken(response.data.authorizationToken);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.PutLoginStatusError, error as AudurAxiosError);
    }
  }

  public async postDocumentSignature(documentId: number): Promise<DocumentSignatureSchema> {
    try {
      const response = await this.axiosInstance.post<DocumentSignatureSchema>(`/documents/${documentId}/signature`);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.PostDocumentSignatureError, error as AudurAxiosError);
    }
  }

  public async getDocumentSignatureStatus(documentId: number, signatureId: number): Promise<DocumentSignatureSchema> {
    try {
      const response = await this.axiosInstance.get<DocumentSignatureSchema>(
        `/documents/${documentId}/signature/${signatureId}`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetDocumentSignatureStatusError, error as AudurAxiosError);
    }
  }

  public async debugCreateClosedTermDepositAccount(): Promise<string> {
    try {
      const response = await this.axiosInstance.post<string>(`/debug/create_closed_term_deposit`);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.DebugCreateClosedTermDepositAccountError, error as AudurAxiosError);
    }
  }

  public async createSavingsAccount(data: CreateSavingsAccountSchema): Promise<AccountSchema> {
    try {
      const response = await this.axiosInstance.post<AccountSchema>(`/accounts/savings`, data);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.CreateSavingsAccountError, error as AudurAxiosError);
    }
  }

  public async putSavingsAccount(data: UpdateSavingsAccountSchema, accountId: number): Promise<string> {
    try {
      const response = await this.axiosInstance.put<string>(`/accounts/savings/${accountId}`, data);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.PutSavingsAccountError, error as AudurAxiosError);
    }
  }

  public async deleteSavingsAccount(accountId: number): Promise<number> {
    try {
      const response = await this.axiosInstance.delete(`/accounts/savings/${accountId}`);
      if (response.status === StatusCode.Ok) {
        return Promise.resolve(response.status);
      }
      throw new Error(ErrorDescription.DeleteSavingsAccountStatusError);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.DeleteSavingsAccountError, error as AudurAxiosError);
    }
  }

  public async createIndexedSavingsAccount(data: CreateIndexedSavingsAccountSchema): Promise<AccountSchema> {
    try {
      const response = await this.axiosInstance.post<AccountSchema>(`/accounts/indexed_savings`, data);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.CreateIndexedSavingsAccountError, error as AudurAxiosError);
    }
  }

  public async getNotices(accountId: number): Promise<NoticeSchema[]> {
    try {
      const response = await this.axiosInstance.get<NoticeSchema[]>(`/notices/${accountId}`);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetNoticesError, error as AudurAxiosError);
    }
  }

  public async postNotice(data: CreateNoticeSchema): Promise<NoticeSchema> {
    try {
      const response = await this.axiosInstance.post<NoticeSchema>(`/notices`, data);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.PostNoticeError, error as AudurAxiosError);
    }
  }

  public async deleteNotice(noticeId: number): Promise<NoticeSchema> {
    try {
      const response = await this.axiosInstance.delete<NoticeSchema>(`/notices/${noticeId}`);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.DeleteNoticeError, error as AudurAxiosError);
    }
  }

  public async putIndexedSavingsAccount(data: UpdateIndexedSavingsAccountSchema, accountId: number): Promise<string> {
    try {
      const response = await this.axiosInstance.put<string>(`/accounts/indexed_savings/${accountId}`, data);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.PutIndexedSavingsAccountError, error as AudurAxiosError);
    }
  }

  public async getUser(ssn: string): Promise<SearchUsersSchema> {
    try {
      const response = await this.axiosInstance.get<SearchUsersSchema>(`/users/search/${ssn}`);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetUserError, error as AudurAxiosError);
    }
  }

  public async getSharedAccountsRequests(): Promise<SharedAccountRequestSchema[]> {
    try {
      const response = await this.axiosInstance.get<SharedAccountRequestSchema[]>(`/accounts/shared_requests`);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetSharedAccountsError, error as AudurAxiosError);
    }
  }

  public async postSharedAccountRequest(data: CreateSharedAccountRequest): Promise<SharedAccountRequestSchema> {
    try {
      const response = await this.axiosInstance.post<SharedAccountRequestSchema>(`/accounts/shared_requests`, data);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.PostSharedAccountsError, error as AudurAxiosError);
    }
  }

  public async getSharedAccountsSummary(): Promise<SharedAccountsSummaryResponse> {
    try {
      const response = await this.axiosInstance.get<SharedAccountsSummaryResponse>(`/accounts/shared_requests_summary`);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetSharedAccountsSummaryError, error as AudurAxiosError);
    }
  }

  public async putAccounts(data: UpdateAccountSchema, accountId: number): Promise<string> {
    try {
      const response = await this.axiosInstance.put<string>(`/accounts/${accountId}`, data);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.UpdateAccountVisibilityError, error as AudurAxiosError);
    }
  }

  /**  /**
   * Methods for company accounts and kyc survey
   */

  public async getUserAccess(onboarding?: boolean): Promise<UserAccessSchema[]> {
    try {
      const response = await this.axiosInstance.get<UserAccessSchema[]>('/users/access', {
        params: { onboarding },
      });
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetUserAccessError, error as AudurAxiosError);
    }
  }

  public async switchUserAccess(ssn: string): Promise<UpdateUserAccessResponse> {
    try {
      const response = await this.axiosInstance.post<UpdateUserAccessResponse>('/users/access', { ssn });
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.SwitchUserAccessError, error as AudurAxiosError);
    }
  }

  public async getCompanyRegistration(): Promise<RegisterCompanyResponse> {
    try {
      const response = await this.axiosInstance.get<RegisterCompanyResponse>('/companies/registration');
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.GetCompanyRegistrationError, error as AudurAxiosError);
    }
  }

  public async updateCompanyRegistration(registrationData: RegisterCompanySchema): Promise<RegisterCompanyResponse> {
    try {
      const response = await this.axiosInstance.put<RegisterCompanyResponse>(
        '/companies/registration',
        registrationData
      );
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.UpdateCompanyRegistrationError, error as AudurAxiosError);
    }
  }

  public async getCurrentCompany(): Promise<AudurCompanySchema> {
    try {
      const response = await this.axiosInstance.get<AudurCompanySchema>('/companies/current');
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.UserProfileError, error as AudurAxiosError);
    }
  }

  public async updateCompany(company: UpdateAudurCompanyRequest): Promise<UpdateAudurCompanyRequest> {
    try {
      const response = await this.axiosInstance.put<UpdateAudurCompanyRequest>('/companies/current', company);
      return Promise.resolve(response.data);
    } catch (error) {
      throw new AudurApiError(ErrorDescription.UpdateCompanyError, error as AudurAxiosError);
    }
  }
}

export default AudurClient;
