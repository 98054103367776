import Image from 'next/image';
import { useRouter } from 'next/router';

import { FlexWrapper, FooterInfo, FooterLink, TopDivider, FooterContainer } from './FooterStyles';
import { AUDUR_LINKS, Links } from '../../../utils/navigation/navigation';
import { TranslatedStrings } from '../../../utils/Translations';

const footerLinks = [
  { title: TranslatedStrings.is.footer.termsAndConditions, url: AUDUR_LINKS.termsAndConditions },
  { title: TranslatedStrings.is.footer.frequentlyAskedQuestions, url: AUDUR_LINKS.frequentlyAskedQuestions },
  { title: TranslatedStrings.is.footer.contactUs, url: AUDUR_LINKS.contactUs },
];

const Footer = () => {
  const router = useRouter();
  if (router.pathname.startsWith(Links.CreateAccount)) return null;

  return (
    <FooterContainer>
      <TopDivider />
      <FlexWrapper>
        <Image height={45} width={115} alt="logo" src="/svg/kvika_white_logo.svg" />
        <FooterInfo>{TranslatedStrings.is.footer.audurPartOfKvika}</FooterInfo>
      </FlexWrapper>
      <FlexWrapper $mobileStretch>
        {footerLinks.map(({ title, url }) => (
          <FooterLink key={title} href={url} target="_blank">
            {title}
          </FooterLink>
        ))}
      </FlexWrapper>
    </FooterContainer>
  );
};

export default Footer;
