import styled from 'styled-components';

type Props = {
  fontSize?: string;
  fontSizeMobile?: string;
  lineHeight?: string;
};

export const StyledTextInfo = styled.p<Props>`
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  white-space: pre-wrap;

  @media all and (max-width: 30em) {
    font-size: ${(props) => props.fontSizeMobile || props.fontSize};
  }
`;
