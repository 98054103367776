const ShieldSvg = () => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="shield-regular 1" clipPath="url(#clip0_5127_4424)">
      <path
        id="Vector"
        d="M10 1.92969L2.85158 4.96094C2.62111 5.05859 2.49611 5.26563 2.50002 5.46094C2.51955 9.03125 4.00002 15.1992 9.78127 17.9648C9.92189 18.0313 10.086 18.0313 10.2227 17.9648C16.0039 15.1953 17.4844 9.03125 17.5 5.45703C17.5 5.26172 17.3789 5.05859 17.1485 4.95703L10 1.92969ZM10.5235 0.113281L17.8789 3.23438C18.7383 3.59766 19.3789 4.44531 19.375 5.46875C19.3555 9.34375 17.7617 16.4336 11.0313 19.6563C10.3789 19.9688 9.62111 19.9688 8.96877 19.6563C2.2383 16.4336 0.644549 9.34375 0.625018 5.46875C0.621112 4.44531 1.26174 3.59766 2.12111 3.23438L9.48049 0.113281C9.64064 0.0390625 9.82033 0 10 0C10.1797 0 10.3594 0.0390625 10.5235 0.113281Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_5127_4424">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ShieldSvg;
