const AudurLogo = ({ width = 619, height = 312 }: { width?: number; height?: number }) => (
  <svg width={width} height={height} viewBox="0 0 619 312" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M544.246 124.27C543.906 124.464 543.615 124.707 543.275 124.901V119.149H518.26V218.905H543.275V166.721C543.466 159.919 546.131 153.42 550.772 148.445C553.25 145.851 556.246 143.808 559.565 142.449C562.884 141.09 566.452 140.445 570.037 140.556V117.328C560.982 117.356 552.092 119.749 544.246 124.27Z"
      fill="#F5F5F5"
    />
    <path
      d="M238.749 168.032C238.749 177.303 238.239 183.363 237.22 186.211C236.068 189.691 233.832 192.711 230.839 194.827C227.729 196.857 224.07 197.883 220.357 197.764C216.679 197.886 213.056 196.85 209.997 194.803C207.095 192.743 204.927 189.809 203.81 186.429C202.516 182.837 201.877 176.43 201.893 167.206V119.828H177.121V160.799C177.121 176.575 178.051 187.425 179.911 193.347C182.272 201.48 187.264 208.6 194.105 213.589C200.753 218.443 209.471 220.871 220.26 220.871C228.881 221.22 237.419 219.062 244.839 214.657C251.387 210.356 256.466 204.158 259.397 196.89C262.09 190.58 263.449 178.808 263.449 161.648V119.828H238.749V168.032Z"
      fill="#F5F5F5"
    />
    <path
      d="M470.947 168.032C470.947 177.303 470.429 183.363 469.394 186.211C468.241 189.691 466.005 192.711 463.012 194.827C459.911 196.857 456.26 197.882 452.555 197.764C448.869 197.886 445.238 196.85 442.17 194.803C439.296 192.732 437.154 189.799 436.056 186.429C434.794 182.837 434.164 176.43 434.164 167.206V119.828H409.221V160.799C409.221 176.527 410.184 187.376 412.109 193.347C414.438 201.457 419.375 208.571 426.157 213.589C432.805 218.443 441.523 220.871 452.312 220.871C460.941 221.219 469.486 219.061 476.915 214.657C483.459 210.352 488.537 204.154 491.473 196.89C494.191 190.58 495.549 178.808 495.549 161.648V119.828H470.947V168.032Z"
      fill="#F5F5F5"
    />
    <path
      d="M127.138 124.901C126.799 124.707 126.532 124.464 126.192 124.27C118.23 119.686 109.199 117.283 100.012 117.304C86.3972 117.452 73.3943 122.985 63.8463 132.696C54.2983 142.406 48.9821 155.503 49.0598 169.124C48.9324 175.914 50.1923 182.658 52.7623 188.943C55.3324 195.228 59.1584 200.923 64.0059 205.677C68.6561 210.556 74.2624 214.424 80.4744 217.038C86.6864 219.653 93.3706 220.957 100.109 220.871C109.34 220.929 118.418 218.524 126.411 213.905L127.138 213.444V218.905H152.178V119.149H127.138V124.901ZM119.617 189.73C117.149 192.354 114.155 194.425 110.831 195.81C107.507 197.195 103.927 197.861 100.328 197.764C96.7578 197.866 93.2067 197.212 89.9075 195.844C86.6083 194.476 83.6354 192.426 81.1841 189.827C76.0314 184.157 73.3202 176.683 73.6383 169.027C73.4985 165.277 74.1019 161.536 75.4136 158.02C76.7253 154.504 78.7195 151.283 81.2812 148.542C83.7291 145.936 86.7002 143.877 89.9994 142.501C93.2986 141.125 96.8515 140.462 100.425 140.556C104.034 140.426 107.629 141.061 110.975 142.421C114.32 143.78 117.34 145.833 119.835 148.445C124.399 153.451 126.986 159.947 127.114 166.721V171.284C126.956 178.143 124.288 184.707 119.617 189.73V189.73Z"
      fill="#F5F5F5"
    />
    <path
      d="M361.52 124.901C361.181 124.707 360.889 124.464 360.574 124.27C352.612 119.686 343.581 117.283 334.394 117.304C320.783 117.464 307.788 123.002 298.242 132.71C288.697 142.418 283.377 155.507 283.442 169.124C283.322 175.917 284.59 182.663 287.168 188.948C289.747 195.233 293.581 200.926 298.436 205.677C303.084 210.553 308.686 214.419 314.894 217.033C321.101 219.648 327.781 220.954 334.515 220.871C343.734 220.965 352.812 218.603 360.817 214.026C368.709 209.553 375.228 203.007 379.669 195.094C383.973 187.568 386.318 179.08 386.487 170.41V170.41V86.9648H361.52V124.901ZM353.999 189.73C351.478 192.261 348.484 194.268 345.186 195.638C341.889 197.008 338.353 197.714 334.782 197.714C331.212 197.714 327.676 197.008 324.379 195.638C321.081 194.268 318.086 192.261 315.566 189.73C310.463 184.076 307.795 176.637 308.141 169.027C308.002 165.277 308.605 161.536 309.917 158.02C311.228 154.504 313.223 151.283 315.784 148.542C318.221 145.948 321.177 143.897 324.458 142.521C327.739 141.145 331.274 140.476 334.831 140.556C338.439 140.429 342.034 141.066 345.379 142.425C348.724 143.784 351.744 145.836 354.241 148.445C358.805 153.451 361.392 159.947 361.52 166.721V170.289C361.574 177.486 358.894 184.434 354.023 189.73H353.999Z"
      fill="#F5F5F5"
    />
    <path d="M361.544 61.9166H336.505V86.9648H361.544V61.9166Z" fill="#F5F5F5" />
    <path
      d="M336.505 281.549V254.268H346.21C349.82 254.268 353.282 255.703 355.835 258.256C358.387 260.81 359.822 264.273 359.822 267.885C359.822 271.496 358.387 274.959 355.835 277.513C353.282 280.066 349.82 281.501 346.21 281.501L336.505 281.549ZM346.016 276.695C348.243 276.577 350.341 275.609 351.876 273.99C353.411 272.372 354.267 270.225 354.267 267.994C354.267 265.762 353.411 263.616 351.876 261.997C350.341 260.378 348.243 259.41 346.016 259.292H341.746V276.695H346.016Z"
      fill="#F5F5F5"
    />
    <path
      d="M382.435 272.059C382.416 274.018 381.817 275.928 380.715 277.548C379.613 279.167 378.057 280.425 376.242 281.161C374.427 281.898 372.435 282.08 370.516 281.687C368.598 281.293 366.839 280.34 365.461 278.947C364.082 277.555 363.147 275.786 362.772 273.863C362.397 271.94 362.599 269.949 363.353 268.141C364.107 266.333 365.378 264.788 367.008 263.702C368.638 262.615 370.553 262.035 372.511 262.035C373.831 262.005 375.143 262.244 376.367 262.738C377.591 263.232 378.702 263.971 379.631 264.91C380.56 265.848 381.287 266.966 381.77 268.195C382.252 269.425 382.478 270.739 382.435 272.059V272.059ZM377.315 272.059C377.212 271.129 376.843 270.248 376.251 269.523C375.659 268.798 374.87 268.26 373.98 267.973C373.089 267.686 372.134 267.663 371.231 267.906C370.327 268.149 369.513 268.649 368.887 269.344C368.261 270.04 367.849 270.901 367.701 271.826C367.553 272.75 367.675 273.697 368.053 274.554C368.431 275.41 369.048 276.139 369.83 276.652C370.613 277.166 371.527 277.442 372.463 277.448C373.147 277.452 373.825 277.311 374.452 277.034C375.078 276.758 375.639 276.351 376.097 275.843C376.556 275.334 376.901 274.733 377.111 274.081C377.321 273.429 377.391 272.74 377.315 272.059V272.059ZM374.016 260.336H369.915L373.409 254.584H379.232L374.016 260.336Z"
      fill="#F5F5F5"
    />
    <path
      d="M392.674 262.618H396.483V267.156H392.674V275.045C392.674 276.719 393.45 277.253 394.906 277.253C395.437 277.265 395.967 277.208 396.483 277.083V281.307C395.56 281.647 394.579 281.804 393.596 281.768C392.804 281.848 392.004 281.753 391.252 281.491C390.5 281.229 389.815 280.806 389.244 280.251C388.673 279.696 388.23 279.023 387.947 278.279C387.663 277.534 387.546 276.737 387.603 275.943V267.156H384.206V262.618H385.152C385.551 262.649 385.952 262.591 386.326 262.45C386.7 262.308 387.038 262.085 387.316 261.798C387.594 261.51 387.805 261.165 387.934 260.787C388.064 260.408 388.108 260.006 388.064 259.608V256.962H392.674V262.618Z"
      fill="#F5F5F5"
    />
    <path
      d="M407.013 262.618H410.798V267.156H407.013V275.045C407.013 276.719 407.765 277.253 409.221 277.253C409.752 277.266 410.282 277.209 410.798 277.083V281.307C409.883 281.645 408.911 281.802 407.935 281.768C407.143 281.848 406.343 281.753 405.591 281.491C404.84 281.229 404.154 280.806 403.583 280.251C403.012 279.696 402.569 279.023 402.286 278.279C402.003 277.534 401.885 276.737 401.942 275.943V267.156H398.521V262.618H399.467C399.867 262.648 400.269 262.59 400.643 262.449C401.018 262.307 401.358 262.085 401.638 261.798C401.918 261.511 402.131 261.167 402.263 260.788C402.396 260.41 402.444 260.007 402.403 259.608V256.962H407.013V262.618Z"
      fill="#F5F5F5"
    />
    <path
      d="M417.689 253.249C418.312 253.249 418.921 253.433 419.439 253.779C419.957 254.125 420.361 254.616 420.601 255.191C420.84 255.766 420.904 256.399 420.784 257.011C420.665 257.622 420.367 258.185 419.928 258.627C419.489 259.069 418.929 259.371 418.319 259.496C417.709 259.62 417.076 259.561 416.499 259.326C415.922 259.091 415.428 258.69 415.078 258.175C414.729 257.659 414.54 257.051 414.535 256.428C414.528 256.011 414.605 255.597 414.761 255.21C414.917 254.823 415.149 254.471 415.443 254.174C415.737 253.878 416.087 253.644 416.473 253.485C416.858 253.326 417.272 253.246 417.689 253.249V253.249ZM415.142 281.549V262.618H420.237V281.549H415.142Z"
      fill="#F5F5F5"
    />
    <path
      d="M437.221 267.69C436.719 267.58 436.206 267.523 435.692 267.52C433.072 267.52 430.84 268.807 430.84 272.86V281.549H425.72V262.618H430.573V265.409C431.087 264.446 431.87 263.654 432.826 263.128C433.783 262.602 434.871 262.366 435.959 262.448C436.355 262.423 436.752 262.423 437.148 262.448L437.221 267.69Z"
      fill="#F5F5F5"
    />
    <path
      d="M459.252 270.215L455.879 273.952V281.549H450.59V254.268H455.879V266.598L466.798 254.268H473.737L462.94 266.137L473.81 281.501H467.162L459.252 270.215Z"
      fill="#F5F5F5"
    />
    <path
      d="M485.068 281.549H479.948L472.232 262.666H477.837L482.544 275.336L487.033 262.666H492.395L485.068 281.549Z"
      fill="#F5F5F5"
    />
    <path
      d="M498.048 253.249C498.671 253.249 499.28 253.433 499.798 253.779C500.316 254.125 500.721 254.616 500.96 255.191C501.2 255.766 501.263 256.399 501.144 257.011C501.024 257.622 500.726 258.185 500.287 258.627C499.848 259.069 499.289 259.371 498.678 259.496C498.068 259.62 497.435 259.561 496.858 259.326C496.281 259.091 495.787 258.69 495.438 258.175C495.088 257.659 494.899 257.051 494.894 256.428C494.888 256.011 494.965 255.597 495.121 255.21C495.276 254.823 495.508 254.471 495.802 254.174C496.096 253.878 496.446 253.644 496.832 253.485C497.218 253.326 497.631 253.246 498.048 253.249ZM495.501 281.549V262.618H500.62V281.549H495.501Z"
      fill="#F5F5F5"
    />
    <path
      d="M516.804 270.555L524.52 281.501H518.26L513.262 274.22L511.151 276.452V281.307H506.055V253.71H511.151V269.632L517.677 262.618H524.35L516.804 270.555Z"
      fill="#F5F5F5"
    />
    <path
      d="M539.563 279.462C538.964 280.276 538.17 280.925 537.254 281.349C536.338 281.773 535.329 281.959 534.322 281.889C533.348 281.923 532.378 281.75 531.475 281.384C530.572 281.017 529.756 280.464 529.081 279.761C528.406 279.058 527.886 278.221 527.556 277.304C527.226 276.387 527.092 275.41 527.164 274.438V262.618H532.26V273.516C532.196 274 532.239 274.493 532.384 274.959C532.53 275.425 532.775 275.855 533.102 276.217C533.43 276.579 533.832 276.866 534.282 277.058C534.731 277.249 535.217 277.341 535.705 277.326C536.196 277.353 536.687 277.273 537.145 277.093C537.602 276.912 538.016 276.635 538.356 276.28C538.697 275.926 538.958 275.501 539.12 275.037C539.282 274.572 539.342 274.078 539.296 273.588V262.618H544.391V278.079C544.394 279.222 544.459 280.364 544.585 281.501H539.733C539.625 280.827 539.568 280.145 539.563 279.462V279.462Z"
      fill="#F5F5F5"
    />
  </svg>
);
export default AudurLogo;
