import Router from 'next/router';
import { trackPage } from '../../utils/AudurAnalytics';
import { getSessionStorageItem, SessionStorageKeys } from '../../utils/browserStorage/SessionStorage';

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', (url) => {
  trackPage(url, { externalId: getSessionStorageItem(SessionStorageKeys.EXTERNAL_ID) });
});

type Props = {
  children: JSX.Element;
};

const Page = ({ children }: Props): JSX.Element => {
  return children;
};

export default Page;
