import { toPercentageString } from '@kvika/string-utils';

import {
  Maybe,
  AudurMarketInterestRatesSavingsMarketInterestRates,
  AudurMarketInterestRatesTermDepositMarketInterestRates,
  AudurMarketInterestRatesFutureMarketInterestRates,
  AudurMarketInterestRates,
  AudurMarketInterestRatesConnectionConnection,
} from './PrismicTypes';

const PRISMIC_REF_API_URL = `https://audur.prismic.io/api/v2`;
const PRISMIC_GRAPHQL_API_URL = `https://audur.prismic.io/graphql`;
const PRISMIC_API_LOCALE = 'is';
const PRISMIC_API_DEFAULT_LOCALE = 'is';
const PRISMIC_API_TOKEN = process.env.NEXT_PUBLIC_PRISMIC_API_TOKEN;

export const prismicConstants = {
  refApiUrl: PRISMIC_REF_API_URL,
  gqlApiUrl: PRISMIC_GRAPHQL_API_URL,
  apiLocale: PRISMIC_API_LOCALE,
  apiDefaultLocale: PRISMIC_API_DEFAULT_LOCALE,
  apiToken: PRISMIC_API_TOKEN,
};

export interface Edge<E> {
  node: E;
}

export interface Connection<E> {
  edges: Maybe<Array<Maybe<Edge<E>>>>;
}

/**
 * Gets a list of nodes of the type E from the connection and edges,
 * returns an empty array if nothing is found
 * @param connection
 * @returns an array of nodes
 */
export const getNodesFromConnection = <E, T extends Connection<E>>(connection: Maybe<T>): Array<E> => {
  if (connection) {
    if (connection.edges) {
      const edges = connection.edges.filter((edge) => edge && 'node' in edge) as Array<Edge<E>>;
      const nodes = edges.map((edge) => edge.node);
      return nodes;
    }
  }
  return [];
};

export const getMarketInterestRatesFromConnection = (
  connection: Maybe<AudurMarketInterestRatesConnectionConnection>
): AudurMarketInterestRates | undefined => {
  const marketInterestRatesList: AudurMarketInterestRates[] = getNodesFromConnection(connection);
  return marketInterestRatesList.find(
    (item) =>
      !!item.savingsMarketInterestRates && !!item.termDepositMarketInterestRates && !!item.futureMarketInterestRates
  );
};

/*
 * Returns a value from a maybe with a default fallback
 * @param value
 * @param defaultValue
 * @returns A value from a maybe
 */
export const valueOrDefault = <T>(value: Maybe<T>, defaultValue: T): T => {
  return value || defaultValue;
};

/**
 * Gets the average market savings interest rate provided with a balance
 * @param balance
 */
export const getAverageMarketSavingsInterestRate = (
  balance: number,
  savingsMarketRates: AudurMarketInterestRatesSavingsMarketInterestRates
): number => {
  if (balance < 1000000) {
    return valueOrDefault(savingsMarketRates.lowDepositRate, 0);
  }
  if (balance < 5000000) {
    return valueOrDefault(savingsMarketRates.midDepositRate, 0);
  }
  if (balance < 20000000) {
    return valueOrDefault(savingsMarketRates.midToHighDepositRate, 0);
  }
  return valueOrDefault(savingsMarketRates.highDepositRate, 0);
};

/**
 * Gets the average market term deposit interest rate provided with the count of months
 * @param months
 */
export const getAverageMarketTermDepositInterestRate = (
  months: number,
  termDepositMarketRates: AudurMarketInterestRatesTermDepositMarketInterestRates
): number => {
  switch (months) {
    case 3:
      return valueOrDefault(termDepositMarketRates.threeMonthTermRate, 0);
    case 6:
      return valueOrDefault(termDepositMarketRates.sixMonthTermRate, 0);
    case 12:
      return valueOrDefault(termDepositMarketRates.twelveMonthTermRate, 0);
    default:
      return valueOrDefault(termDepositMarketRates.threeMonthTermRate, 0);
  }
};

/**
 * Gets the average market future account interest rate
 */
export const getAverageMarketFutureInterestRate = (
  futureMarketRates: AudurMarketInterestRatesFutureMarketInterestRates
): number => {
  return valueOrDefault(futureMarketRates.averageMarketFutureRate, 0);
};

/**
 * Gets average market interest rates for term deposit accounts according to Aurbjorg.is
 * @param months
 */
export const getMarketTermDepositInterestText = (
  months: number,
  termDepositMarketRates?: AudurMarketInterestRatesTermDepositMarketInterestRates,
  marketInterestRateString?: string
): string => {
  const marketRateString = termDepositMarketRates
    ? toPercentageString(getAverageMarketTermDepositInterestRate(months, termDepositMarketRates), 2)
    : marketInterestRateString;
  return `Meðalvextir á sambærilegum innlánsreikningum sem eru bundnir í ${months} mánuði eru nú ${marketRateString} skv Aurbjörgu.`;
};

/**
 * Gets the market interest rates for savings accounts based on Aurbjorg.is
 */
export const getAurbjorgSavingsAccountMarketInterestText = (
  savingsMarketRates: AudurMarketInterestRatesSavingsMarketInterestRates
): string => {
  const { lowDepositRate, midDepositRate, midToHighDepositRate, highDepositRate } = savingsMarketRates;
  return `Meðalvextir á markaði skv. Aurbjörgu, www.aurbjorg.is, fara stighækkandi eftir innstæðu; 0-1 m.kr. ${toPercentageString(
    valueOrDefault(lowDepositRate, 0),
    2
  )}, 1-5 m.kr. ${toPercentageString(valueOrDefault(midDepositRate, 0), 2)}, 5-20 m.kr. ${toPercentageString(
    valueOrDefault(midToHighDepositRate, 0),
    2
  )}, +20 m.kr. ${toPercentageString(valueOrDefault(highDepositRate, 0), 2)}`;
};
