const ChevronRightSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="chevron-right  ">
      <path
        id="Vector"
        d="M11.2828 7.46876C11.5766 7.76251 11.5766 8.23751 11.2828 8.52814L5.28281 14.5313C4.98906 14.825 4.51406 14.825 4.22344 14.5313C3.93281 14.2375 3.92969 13.7625 4.22344 13.4719L9.69219 8.00314L4.22031 2.53126C3.92656 2.23751 3.92656 1.76251 4.22031 1.47189C4.51406 1.18126 4.98906 1.17814 5.27969 1.47189L11.2828 7.46876Z"
        fill="#AAADB3"
      />
    </g>
  </svg>
);
export default ChevronRightSvg;
