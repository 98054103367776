export enum GeneralStrings {
  Quantity = 'Fjöldi',
  Login = 'Innskráning',
  ElectronicId = 'Rafræn skilríki',
  ElectronicSim = 'Auðkennisappið',
  FaceId = 'Innskráning með Face ID',
  Forward = 'Áfram',
  Back = 'Til baka',
  Cancel = 'Hætta við',
  Name = 'Nafn',
  Yes = 'Já',
  No = 'Nei',
  NoThanks = 'Nei takk',
  GoodDay = 'Góðan daginn',
  GoodNight = 'Góða kvöldið',
  StartSaving = 'Byrjaðu að spara',
  OpenBank = 'Opna banka',
  SavingsAccount = 'Sparnaðarreikningur',
  TermDepositAccounts = 'Bundnir reikningar',
  TermDepositAccount = 'Bundinn reikningur',
  CalculateButtonText = 'Reikna áætlaða ávöxtun',
  CalculateShortButtonText = 'Reikna ávöxtun',
  Iceland = 'Ísland',
  Other = 'Annað',
  Ok = 'Ok',
  Close = 'Loka',
  ReadConditions = 'Skoða skilmála',
  Conditions = 'Skilmálar',
  Congratulations = 'Til hamingju',
  Email = 'Netfang',
  PinNumber = 'Leyninúmer',
  LoginWithFaceId = 'Innskráning með Face ID',
  LogOut = 'Útskrá',
  Settings = 'Stillingar',
  Confirm = 'Staðfesta',
  EmailNotValid = 'Ógilt netfang',
  PhoneNumberNotValid = 'Ógilt símanúmer',
  Total = 'Samtals',
  Amount = 'Upphæð',
  CreateAccount = 'Stofna reikning',
  Highlights = 'Það helsta',
  ErrorMessage = 'Eitthvað fór úrskeiðis, vinsamlegast reynið aftur síðar.',
  NoTransactions = 'Engar færslur fundust',
  TelephoneNumber = 'Símanúmer',
  PowerOfAttorneyNumber = 'Símanúmer prókúruhafa',
  SocialSecurityNumber = 'Kennitala',
  UnpaidInterest = 'Áunnir ógreiddir vextir',
  UnpaidInterestAndIndexation = 'Áunnir ógreiddir vextir og verðbætur',
  YourUnknownBank = 'Óþekktur banki',
  YourUnknownBankInSentence = 'þíns banka',
  CopyYourAccountToClipboard = 'Afritaðu nýja sparnaðarreiknings númerið þitt með því að ýta á það.',
  GoToSplashScreen = 'Fara á upphafsskjá',
  CombinedBalance = 'Samanlögð innstæða',
  EarnedInterestInfo = 'Vaxtatekjur eru greiddar inn á innlánsreikninga mánaðarlega',
  InterestTooltipInfo = 'Vaxtatekjur eru greiddar inn á sparnaðarreikninginn mánaðarlega.',
  Deposit = 'Leggja inn',
  Transfer = 'Taka út',
  Accounts = 'Reikningar',
  AccountOverview = 'Reikningsyfirlit',
  Change = 'Breyta',
  GoToLoginPage = 'Fara á upphafssíðu',
  Loading = 'Í vinnslu...',
  TotalBalance = 'Samanlögð innstæða',
  GetAppAndroid = 'Sækja appið - Android',
  GetAppiOS = 'Sækja appið - iOS',
  Indexed = 'Verðtryggt',
  NonIndexed = 'Óverðtryggt',
  ExpoUpdateAvailable = 'Ný uppfærsla í boði',
  RestartToUpdate = 'Til að sjá nýjustu breytingar þarf að endurræsa appið',
  RestartApp = 'Endurræsa',
  NotNow = 'Ekki núna',
  SignInAgain = 'Vinsamlegast skráðu þig inn aftur',
  SorryForTheTrouble = 'Afsakið vesenið!',
  Attention = 'Athugið',
  ChildrensAccounts = 'Reikningar barna',
  New = 'Nýtt',
  GoToSettings = 'Fara í stillingar',
  NotEnoughBalance = 'Því miður er engin innstæða til ráðstöfunar!',
  NotEnoughBalanceShort = 'Engin innstæða til ráðstöfunar',
  PrintAccountOverview = 'Prenta reikningsyfirlit',
  UseCopiedInformation = 'Nota afritaðar upplýsingar?',
  AccountInformation = 'Reikningsupplýsingar',
  UseAccount = 'Nota reikning',
  TryAgain = 'Reyna aftur',
}

export enum RBBussinessHours {
  from = '09:00:00',
  to = '16:30:00',
}

export enum ProfileStrings {
  TermsAndConditions = 'Skilmálar og persónuvernd',
  SendATip = 'Senda ábendingu eða hrós',
  Version = 'Útgáfa',
  ShowChildrensAccounts = 'Sýna reikninga þeirra barna sem eru á þínu forræði.',
}

export enum NewAccountStrings {
  ISUSATaxPayerText = 'Ég staðfesti að ég er ekki bandarískur skattaðili',
  NOTUSATaxPayer = 'Ekki bandarískur skattaðili',
  USATaxPayer = 'Bandarískur skattaðili',
  USATaxPayerInfoText = 'Samkvæmt samningi Íslands og Bandaríkjanna um regluleg upplýsingaskipti vegna fjármálastofnana (FATCA samningurinn) teljast bandarískir ríkisborgarar vera bandarískir aðilar og á það sama við um einstaklinga sem teljast vera skattalega heimilisfastir í Bandaríkjunum samkvæmt þarlendum skattalögum',
  ApplicationConfirmed = 'Umsókn þín hefur verið samþykkt',
  SignDocument = 'Undirrita',
  Borrowed = 'Lántaka',
  Salary = 'Laun',
  Inheritance = 'Arfur',
  CapitalGain = 'Söluhagnaður',
  NewAccount = 'Nýskráning',
  SocialSecurityNumber = 'Kennitala',
  Address = 'Heimilisfang',
  City = 'Borg',
  PostalCode = 'Póstnúmer',
  TinNumber = 'Tin númer',
  Country = 'Land',
  Bank = 'Banki',
  HB = 'HB',
  DisposableAccount = 'Ráðstöfunarreikningur',
  DisposableAccountBreaking = 'Ráðstöfunar-reikningur',
  BankAccountNumber = 'Reikningsnúmer',
  ChooseCountry = 'Veldu land',
  DoYouWantToCancelRegistration = 'Viltu hætta við nýskráninguna?',
  PoliticalReferenceText = 'Ég staðfesti að ég, mín nánasta fjölskylda og nánir samstarfsmenn erum ekki og höfum ekki verið háttsett í opinberri þjónustu síðastliðna tólf mánuði',
  PoliticalReferenceInfoText = 'Samkvæmt lögum um aðgerðir gegn peningaþvætti og fjármögnun hryðjuverka er fjármálafyrirtækjum skylt að kanna hvort viðskiptamaður sinn, hans nánasta fjölskylda eða nánir samstarfsmenn séu eða hafi verið háttsettir í opinberri þjónustu á síðustu 12 mánuðum. Til háttsettra einstaklinga í opinberri þjónustu teljast: a) þjóðhöfðingjar, ráðherrar og staðgenglar ráðherra eða aðstoðarráðherrar, b) þingmenn, c) einstaklingar í framkvæmdastjórn stjórnmálaflokka, d) hæstaréttardómarar, dómarar við stjórnlagadómstóla eða aðrir háttsettir dómarar við dómstóla þaðan sem niðurstöðu er ekki hægt að áfrýja nema í undantekningartilvikum, e) dómarar við endurskoðunardómstóla og stjórnarmenn seðlabanka, f) sendiherrar, staðgenglar sendiherra og háttsettir yfirmenn herja, g) fulltrúar í stjórn, framkvæmdastjórn eða eftirlitsstjórn fyrirtækja í eigu ríkis, h) framkvæmdastjórar, aðstoðarfram- kvæmdastjórar og stjórnarmenn alþjóðasamtaka og -stofnana. Störf sem talin eru upp í a–h-liðum eiga ekki við um millistjórnendur. Til nánustu fjölskyldu teljast: a) maki b) sambúðarmaki í skráðri sambúð c) börn, stjúpbörn og makar þeirra eða sambúðarmakar í skráðri sambúð d) foreldrar Til náinna samstarfsmanna teljast: a) einstaklingar sem vitað er að hafi verið raunverulegir eigendur lögaðila með einstaklingi sem er eða hefur verið háttsettur og gegnt opinberri þjónustu eða aðrir þekktir samstarfsmenn, b) einstaklingar sem átt hafa náin viðskiptatengsl við einstakling sem er eða hefur verið háttsettur og gegnt opinberri þjónustu, c) einstaklingur sem er einn raunverulegur eigandi lögaðila sem vitað er að var stofnaður til hagsbóta fyrir einstakling sem er eða hefur verið háttsettur og gegnt opinberri þjónustu.',
  PoliticalRefrenceHeader = 'Háttsett í opinberri þjónustu',
  IAmInfoText = 'Það þarf að staðfesta að þú sért launþegi, sjálfstæður atvinnurekandi.',
  IAm = 'Ég er',
  OwnerOfMoneyInfoText = 'Samkvæmt lögum um aðgerðir gegn peningaþvætti og fjármögnun hryðjuverka er fjármálafyrirtækjum skylt að ganga úr skugga um að fyrir liggi upplýsingar um raunverulegan eiganda þeirra fjármuna sem færðir eru bankanum til varðveislu. Í tilviki einstaklinga er raunverulegur eigandi sá sem stýrir þeim viðskiptamanni í hvers nafni viðskipti eru framkvæmd.',
  OwnerOfMoneyHeaderText = 'Raunverulegur eigandi',
  TaxOriginText = 'Ég er með skattalega heimilisfesti í eftirfarandi landi',
  TaxOriginInfoText = 'Ef þú ert óviss hvar þú ert með skattalega heimilisfesti bendum við þér á að hafa samband við ríkisskattstjóra en hann hefur úrskurðarvald um hverjir skuli teljast heimilisfastir hér á landi.',
  TaxOrigin = 'Skattalegt heimilisfesti',
  TIN = 'TIN',
  TINInfoText = 'Lönd nota svokallað TIN eða “tax idendification number” til að auðkenna skattgreiðendur og auðvelda stýringu skattamála. Á Íslandi er kennitala viðkomandi notuð sem TIN. Ef þú ert óviss um þitt TIN bendum við þér á að hafa samband við skattayfirvöld í viðkomandi landi.',
  ConfirmedOwnerText = 'Ég staðfesti að ég er raunverulegur eigandi þeirra fjármuna sem færðir eru bankanum til varðveislu.',
  OriginOfFund = 'Uppruni fjármuna',
  PinNumberText = 'Veldu 4 stafa leyninúmer fyrir nýja reikninginn hjá Auði',
  RegisterYourDisposableAccount = 'Skráðu ráðstöfunarreikning',
  TransferableAccountForAudur = 'Hann verður sá reikningur sem þú millifærir á við úttekt af reikningnum þínum hjá Auði.',
  ChooseYourBankAccount = 'Velja þarf reikning í þinni eigu',
  Employment = 'Starfssvið',
  PersonalInfo = 'Persónulegar upplýsingar',
  PoliticalRisk = 'Stjórnmálaleg tengsl',
  NoPoliticalRisk = 'Engin stjórnmálaleg tengsl',
  Overview = 'Yfirlit',
  ImNotPoliticalRisk = 'Ég hef engin stjórnmálaleg tengsl',
  SigningProcessStarted = 'Undirritunarferli hafið',
  SigningCompanyProcessStarted = 'Skrifaðu undir með rafrænum skilríkjum',
  FinishApplication = 'Til að klára umsóknina þarft þú að undirrita skilmála Kviku banka hf.',
  FinishApplicationTitle = 'Þjónustuskilmálar',
  OverviewCoditionButton = 'Lesa skilmála Kviku banka hf.',
  YourVerificationCode = 'Öryggisnúmerið þitt er',
  VerificationCode = 'Öryggisnúmer',
  ESIMText = 'Opnaðu auðkennisappið til að klára undirritun.',
  PleaseCheckPhone = 'Vinsamlegast fylgist með boðum sem koma í síma til að skrifa undir.',
}

export enum AccountNotFoundStrings {
  Hello = 'Hæ, ',
  NoAccountFound = 'Við fundum ekki reikning fyrir þig',
  CreateAccountInfoText = 'Smelltu á stofna reikning og eftir örskamma stund áttu sparnaðarreikning',
}

export enum PEPUserStrings {
  SignPowerOfAtterney = 'Undirrita umboð',
  ReadPowerOfAttorney = 'Skoða umboð',
  ApplicationReceived = 'Umsókn móttekin',
  PEPUserHeadingText = 'Háttsett í opinberri þjónustu',
  PEPUserApplicationInfoText = 'Þar sem þú/eða aðilar tengdir þér hafa verið háttsettir opinberri þjónustu á síðustu 12 mánuðum viljum við skoða umsóknina þína nánar. Við höfum samband við þig í gegnum netfangið sem þú gafst okkur.',
  PEPUserText = 'Þar sem þú og/eða aðilar tengdir þér hafið verið háttsett í opinberri þjónustu á síðustu 12 mánuðum ber okkur að sannreyna uppruna þeirra fjármuna sem þú munt leggja inn til Auðar. Til að við getum aflað nauðsynlegra upplýsinga þarft þú að undirrita umboð þess efnis að Kviku Banka hf. sé heimilt að sækja upplýsingar úr síðustu tveimur skattaframtölum þínum til ríkisskattstjóra.',
}

export enum OnboardingStrings {
  Welcome = 'Velkomin',
  Savings = 'Sparnaður',
  Audur = 'Auður',
  IntroText = 'Halló, ég er',
  AudurIntroText = 'Auður er fjármálaþjónusta á netinu sem býður upp á innlánsreikninga fyrir einstaklinga.',
  LoginIntroText = 'Skráðu þig inn eða stofnaðu reikning, það tekur enga stund.',
  SavingsIntroText = 'Sparnaðarreikningur eða bundinn reikningur? Þitt er valið. Báðir gefa sanngjarna vexti.',
}

export enum SavingsAccountStrings {
  SavingsAccountSubHeader = 'Þessi reikningur hentar þeim sem vilja góða innlánsvexti á sparnaðinn sinn án bindingar',
  SavingsAccountPayment = 'Reikningurinn er óbundinn og eru áfallnir vextir greiddir mánaðarlega',
  SavingsAccountWithdrawal = 'Innstæðan er alltaf laus til úttektar. Óverðtryggðir breytilegir vextir sem taka mið af vaxtastigi á fjármálamarkaði á hverjum tíma',
  SavingsAccountCredit = 'Lágmarksinnstæða reiknings er 250.000 kr. en heimilt er að hafa reikninginn undir lágmarksinnstæðu í 180 daga',
  SavingsShort = 'Sparnaðarreikningur',
  SavingsAccountsSlogan = 'Það heitir ekki ávöxtun nema þú hagnist á því',
}

export enum ChildrensAccountsStrings {
  ChildrensAccounts = 'Reikningar barna',
  ChildAccount = 'Reikningur barns',
  ChildAccountsSlogan = 'Það heitir ekki ávöxtun nema börnin hagnist á því',
  ChildAccountsAvailable = 'Nú bjóðum við 13-17 ára velkomin í viðskipti! Auður lækkar aldursþröskuldinn svo allt frá 13 ára geta nú fengið hæstu mögulegu vexti á óbundinn sparnað. Forráðamenn geta fengið skoðunaraðgang með því að fara í Stillingar.',
  ChildTurnsEighteenTitle = 'Tíminn líður, trúðu mér',
  ChildTurnsEighteenDescription = 'Nú þegar 18 ára afmælismánuður barnsins þíns er runninn upp er innstæðan á Græna framtíðarreikningum laus til ráðstöfunar! Innstæðan er laus í einn mánuð en endurbindist svo aftur í 5 mánuði. Til þess að sjá reikninginn verður eigandi hans að vera skráður í viðskipti við Auði.',
}

export enum TermAccountStrings {
  TermAccountSubHeader = 'Þessi reikningur hentar þeim sem vilja fá enn betri vexti gegn því að binda peningana í þrjá, sex eða tólf mánuði',
  TermAccountInterest = 'Vextir á bundnum reikningum taka mið af vaxtastigi á fjármálamarkaði á hverjum tíma',
  TermAccountAbout = 'Reikningurinn er fastvaxtareikningur en það þýðir að þeir vextir sem eru í gildi á stofndegi reiknings gilda út allan binditímann',
  TermAccountTermInfo = 'Þegar binditíma reiknings lýkur lokast reikningurinn og innstæða ásamt áföllnum vöxtum að frádregnum fjármagnstekjuskatti er millifærð inn á óbundna sparnaðarreikninginn þinn hjá Auði',
  TermAccountType = 'Bundnir reikningar Auðar eru óverðtryggðir',
  TermAccountTermPayments = 'Vextir eru greiddir út um hver mánaðarmót yfir binditíma og millifærðir á óbundna sparnaðarreikning þinn hjá Auði og því lausir til úttektar á greiðsludegi',
  TermAccountShort = 'Bundinn reikningur',
  CreateTermAccount = 'Stofna bundinn reikning',
  TermAccountTo = 'Bundinn reikningur til',
  InterestsInYear = 'Vextir á ársgrundvelli',
  ConfirmAccountCreationText = 'Vinsamlegast staðfestu stofnun reikningins. Við það er valin innstæða millifærð af sparnaðarreikningi þínum hjá Auði yfir á bundinn reikning.',
  Congratulations = 'Til hamingju, njóttu þess að sjá peningana vaxa!',
  CreateAccountContract = 'Samningur um stofnun bundins reiknings hjá Auði/Kviku banka hf.',
  SwitchConfirmationText = 'Ég hef kynnt mér ofangreindan samning og samþykki hann.',
  ClosedTermAccounts = 'Lokaðir bundnir reikningar',
  ClosedAccounts = 'Lokaðir reikningar',
  AmountToTransferToTerm = 'Hvaða upphæð viltu millifæra á bundna reikninginn?',
  SaveAndEnjoy = 'Fyrst sparnaður svo gleði',
  PleaseConfirmCreation = 'Vinsamlegast staðfestu stofnun reikningins. Við það er valin innstæða millifærð af sparnaðarreikningi þínum hjá Auði yfir á bundinn reikning.',
  AmountTransferred = 'Millifærð upphæð af sparnaðarreikningi',
  ClosedUntil = 'Bundinn reikningur til',
  ChoosePeriod = 'Veldu tímann sem þú vilt binda peningana.',
  AmountToTransferToTermDeposit = 'Hvaða upphæð viltu millifæra á bundna reikninginn?',
  TermsModalTitle = 'Samningur um stofnun bundins reiknings hjá Auði/Kviku banka hf.',
  TermsModalText1 = 'Umsækjandi hefur sótt um að opna bundinn reikning hjá Auði (Kviku banka hf., kt. 540502-2930). Reikningurinn er bundinn í XX mánuði.',
  TermsModalText2 = 'Reikningurinn er bundinn með föstum vöxtum meðan á binditíma stendur. Að binditíma loknum lokast reikningurinn og innstæða ásamt vöxtum er millifærð á sparnaðarreikning umsækjanda hjá Auði. Vaxtagreiðslum er ráðstafað inn á sparnaðarreikning umsækjanda hjá Auði mánaðarlega eða jafnóðum og þær greiðast á meðan binditíma stendur. Innstæðu reiknings skal ráðstafað inn á sparnaðarreikning umsækjanda hjá Auði, að binditíma loknum.',
  TermsModalText3 = 'Umsækjandi og Kvika banki hf., kt. 540502-2930, gera með sér þennan samning um stofnun bundins reiknings hjá Auði. Auður er vörumerki fyrir rafræna þjónustu um fjármál og er hluti af þeirri þjónustu sem Kvika býður viðskiptavinum upp á. Kvika er með viðskiptabankaleyfi skv. lögum um fjármálafyrirtæki. Um bundna reikninga Auðar og samninginn gilda eftirfarandi skilmálar, auk almennra skilmála Auðar, eftir því sem við á, sem aðgengilegir eru á heimasíðu Auðar, audur.is, og almennra skilmála vegna viðskipta hjá Kviku banka hf. og aðgengilegir eru á heimasíðu Kviku, kvika.is.',
  TermsModalText4 = 'Umsækjandi gerir sér grein fyrir að hann hefur stofnað reikning sem er bundinn samkvæmt ofangreindu og ekki hægt að taka út af reikningnum fyrr en að binditíma loknum. Eingöngu er hægt að leggja inn á reikninginn við stofnun hans, með því að millifæra út af sparnaðarreikningi umsækjanda hjá Auði. Engin lágmarksupphæð er á reikningnum. Að binditíma loknum verður innstæða lögð inn á sparnaðarreikning umsækjanda hjá Auði og reikningnum lokað. Umsækjandi getur á meðan á binditíma stendur hvorki millifært inn á reikninginn né tekið út af honum.',
  TermsModalText5 = 'Umsækjandi staðfestir að hafa kynnt sér og samþykkir almenna viðskiptaskilmála Kviku banka hf., almenna skilmála Auðar og vaxtatöflu. Gildandi viðskiptaskilmálar Kviku banka hf., skilmálar Auðar og vaxtatafla á hverjum tíma teljast vera hluti samnings þessa. Þessir skilmálar ganga framar almennum skilmálum Auðar að því er varðar binditíma reikningsins, innborganir eða úttektir af reikningnum, auk þess sem vextir reikningsins eru fastir út binditímann.',
  TermsModalText6 = 'Umsækjandi veitir Kviku banka hf. heimild til vinnslu persónuupplýsinga sem nauðsynleg er til þess að framkvæma samning þennan. Tilgangur vinnslu persónuupplýsinganna er til þess að bankinn geti efnt skuldbindingar sínar samkvæmt samningi þessum og er geymslutími persónuupplýsinganna 7 ár frá lokum samnings skv. lögum um bókhald.',
  TermsModalText7 = 'Með samþykki umsækjanda á skilmálum þessum samþykkir hann bókunaraðferðir bankans, t.d. varðandi vaxtareikning. Almennt er vaxtaárið 360 dagar. Vaxtatímabil miðast við 30 daga í mánuði, en upphaf og lok tímabils fer eftir tegund innlánsreiknings hverju sinni. Almennt bera innborganir vexti frá og með þeim tíma er fjármunir eru eignfærðir á reikning en ef reikningur er stofnaður um helgi eða eftir klukkan 21:00 á virkum degi er bókunardagur innborgunar næsti virki dagur. Síðasti vaxtadagur er dagurinn áður en úttekt er skuldfærð á reikning. Það ræðst af eðli og tegundum reikninga hvenær vextir leggjast við höfuðstól. Fjármagnstekjuskattur er skuldfærður við vaxtabókun.',
  TermsModalText8 = 'Ef ofangreindum atriðum er ekki framfylgt getur umsækjandi orðið ábyrg/-ur gagnvart bankanum á tjóni, sem leiða kann af misnotkun reikningsins.',
  TryAgainTomorrow = 'Vegna takmarkana hjá Reiknistofu bankanna er ekki hægt að stofna bundinn reikning sama dag og sparnaðarreikningur hjá Auði var stofnaður. Vinsamlegast reyndu aftur á morgun.',
  SopraMigrationHeader = 'Kerfisuppfærsla í gangi',
  SopraMigrationSubheader = 'Vegna kerfisuppfærslu verða ýmsar aðgerðir ekki mögulegar yfir helgina, m.a. að stofna nýja reikninga. Við biðjumst velvirðingar á þessu.',
}

export enum TransactionStrings {
  Date = 'Dagsetning',
  Description = 'Skýring',
  LatestTransactions = 'Síðast',
  Transfer = 'Millifæra',
  TransferComplete = 'Millifærslu lokið',
  AccountNumber = 'Reikningsnr.',
  EarnedInterest = 'Áunnir vextir',
  InterestPercentage = 'Vaxtaprósenta',
  Balance = 'Innstæða',
  AccountStatus = 'Staða',
  InterestsInTerm = 'Vaxtatekjur á tímabilinu',
  InterestIncome = 'Vaxtatekjur',
  InflationCompensation = 'Verðbætur',
  InterestIncomeAndInflationCompensation = 'Vaxtatekjur og verðbætur',
  TransferToDepositAccount = 'Lagt inn á ráðstöfunarreikninginn',
  TransactionComplete = 'Voru millifærðar á ráðstöfunarreikninginn',
  AmountToTransferToExternal = 'Hvaða upphæð viltu millifæra á ráðstöfunarreikninginn?',
  TransactionOverview = 'Færsluyfirlit',
  PickYear = 'Veldu ár',
  LastTransactions = 'Síðustu færslur',
  PrintOverview = 'Prenta yfirlit',
  Deposit = 'Leggja inn',
  CopyAccountNumber = 'Afrita reikningsnúmer',
  EarnedInterestAndInflationCompensation = 'Áunnir vextir/verðbætur',
  PickLanguage = 'Veldu tungumál',
  LargeAmountOutsideRBHoursInfoText = 'Stórgreiðslukerfi Seðlabankans lokar klukkan 16:30 alla virka daga og er lokað um helgar og á almennum frídögum. Allar millifærslur að upphæð 10 m.kr. eða hærri, framkvæmdar eftir þann tíma, fara því á bið og verða framkvæmdar milli klukkan 09:00 - 09:30 næsta virka dag. Þá birtist millifærslan á reikningsyfirlitinu.',
  Interests = 'Vextir',
  DepositToAudur = 'Leggja inn á Auði',
  DepositToAudurSubtitle = 'Því meira sem þú leggur inn því meira getur þú tekið út. Eins og er átt þú enga lausa innstæðu til ráðstöfunar en þú getur alltaf lagt inn!',
}

export enum CalculatorStrings {
  SavingsShorthand = 'Óbundinn',
  TermDepositShorthand = 'Bundinn',
  FutureShorthand = 'Framtíðar',
  CalculatorHeading = 'Reikna áætlaða ávöxtun',
  CalculatorHeadingDescription = 'Hér má reikna út áætlaða ávöxtun innstæðu hjá Auði að viðbættum áætluðum mánaðarlegum sparnaði miðað við núverandi vaxtakjör. Vaxtakjör eru háð ákvörðun Auðar hverju sinni og taka mið af vaxtastigi á fjármálamarkaði.',
  AverageMarketInterest = 'Meðalvextir á markaði',
  AverageMarketDescription = 'Meðalvextir á markaði skv. Aurbjörgu, www.aurbjorg.is, fara stighækkandi eftir innstæðu; 0-1 m.kr. 0,14%, 1-5 m.kr. 0,17%, 5-20 m.kr. 0,19%, +20 m.kr. 0,22%.',
  OriginalBalance = 'Upphafsstaða',
  AudurInterest = 'Vextir hjá Auði ',
  AverageTermInterest = 'Meðalvextir bundinna innlána',
  ProjectedBalance = 'Áætluð innstæða',
  ProjectedInterest = 'Áætlaðar vaxtatekjur',
  MonthlyDeposit = 'Mánaðarleg innborgun',
  Time = 'Tími',
  Months = 'mánuðir',
  Month = 'mánuður',
  KronaCurrencySymbol = 'kr.',
  InterestPercentageAndTermString = 'Vaxtaprósenta og binditími',
  CalculatorTooltip = 'Hér má reikna út áætlaða ávöxtun innstæðu hjá Auði að viðbættum áætluðum mánaðarlegum sparnaði miðað við núverandi vaxtakjör. Vaxtakjör eru háð ákvörðun Auðar hverju sinni og taka mið af vaxtastigi á fjármálamarkaði.',
  ProjectedYearlyInflation = 'Áætluð verðbólga á ársgrundvelli',
  EstimatedBalance = 'Áætluð innstæða í lok tímabils',
}

export enum BiometricStringsIOS {
  TouchID = 'Touch ID',
  FaceID = 'Face ID',
  BiometricID = 'Biometric auðkenni',
  LoginWithTouchID = 'Innskrá með Touch ID',
  LoginWithFaceID = 'Innskrá með Face ID',
  LoginWithBiometricID = 'Innskrá með biometric auðkenni',
  EnableFaceID = 'Virkja Face ID?',
  EnableFaceIDDescription = 'Viltu nota Face ID til að auðkenna þig inn í Auðar appið?',
  EnableTouchID = 'Virkja Touch ID?',
  EnableTouchIDDescription = 'Viltu nota Touch ID til að auðkenna þig inn í Auðar appið?',
}

export enum BiometricStringsAndroid {
  Touch = 'Fingrafar',
  Face = 'Andlitsgreining',
  BiometricID = 'Biometric auðkenni',
  LoginWithTouch = 'Innskrá með fingrafari',
  LoginWithFace = 'Innskrá með andliti',
  LoginWithBiometricID = 'Biometric auðkenni',
  EnableFace = 'Virkja innskráningu með andlitsgreiningu?',
  EnableFaceDescription = 'Viltu nota andlitsgreiningu til að auðkenna þig inn í Auðar appið?',
  EnableTouch = 'Virkja innskráningu með fingrafari?',
  EnableTouchDescription = 'Viltu nota fingrafar til að auðkenna þig inn í Auðar appið?',
  EnableBiometricID = 'Virkja innskráningu með biometric auðkenni?',
  EnableBiometricIDDescription = 'Viltu nota biometric auðkenni til að auðkenna þig inn í Auðar appið?',
}

export enum ErrorStrings {
  ErrorHeadline = 'Eitthvað fór úrskeiðis',
  TermAccountCreationError = 'Ekki tókst að búa til bundinn reikning',
  AudkenniAlertTitleError = 'Villa frá Auðkenni',
  AudkenniDescriptionError = 'Ekki tókst að auðkenna notanda',
  UserCanceledAuthenticationError = 'Notandi hætti við auðkenningu.',
  BiometricsAlertTitleError = 'Innskráning útrunnin',
  BiometricsDescriptionError = 'Innskráning er útrunnin. Þú þarft að skrá þig inn með rafrænum skilríkjum',
  LoginTimedOutError = 'Innskráning rann út á tíma.',
  UserProfileAlertTitleError = 'Notandi fannst ekki',
  UserProfileAlertDescriptionError = 'Enginn notandi fannst, vinsamlega innskráðu þig aftur',
  TransferError = 'Millifærsla tókst ekki',
  TransactionsError = 'Ekki tókst að ná í millifærslur',
  LogoutError = 'Ekki tókst að skrá notanda út',
  UpdateUserError = 'Ekki tókst að uppfæra notanda',
  RegisterUserError = 'Ekki tókst að búa til reikning fyrir notanda',
  AccountsError = 'Ekki tókst að ná í reikninga',
  SignDocumentError = 'Ekki tókst að undirrita umboð',
  NoDocumentFound = 'Ekki tókst að ná í skjal',
  CheckoutChildInsuranceError = 'Ekki tókst að bæta við barnatryggingu. Vinsamlegast reynið aftur síðar.',
  SignDocumentCancellationError = 'Ekki tókst að undirrita, notandi hætti við',
  SignDocumentTimeOutError = 'Ekki tókst að undirrita, undrittun rann út á tíma',
  InsufficientFundsError = 'Ekki næg innstæða á úttektarreikningi. Athugaðu að fyrri millifærslur gætu verið í bið.',
  NoticesError = 'Ekki tókst að ná í færslur í bið',
  AlreadyPendinLogin = 'Auðkenningarbeiðni er nú þegar í gangi fyrir símanúmerið',
  ChildrenCantShareAccounts = 'Börn geta ekki deilt reikning/um.',
  CantShareAccounWithYourself = 'Þú getur ekki deilt reikning/um með sjálfum þér.',
  SharingAccontError = 'Ekki tókst að deila reikning/um.',
  UpdatingAccountVisibilityError = 'Ekki tókst að uppfæra hvaða reikningar sjást á yfirlitinu þínu',
  ServerTimeoutError = 'Eitthvað veldur því að ekki næst samband við vefþjón Auðar. Það getur verið vegna lélegs netsambands eða vegna bilunar í kerfum okkar. \n \nVinsamlegast reyndu aftur.',
  ServerTimeoutHeadlineError = 'Ekki náðist samband við vefþjón',
}

export enum SegmentTrackingStrings {
  ElectronicIdLoginButtonPressed = 'Login with Auðkenni Button Pressed',
  BiometricLoginButtonPressed = 'Login with Biometrics Button Pressed',
  BiometricLoginSuccess = 'Logged in with Biometrics',
  ElectronicIdLoginSuccess = 'Logged in with Audkenni',
  NewUserElectronicIdLoginSuccess = 'New User - Logged in with Audkenni',
  OpenUserProfile = 'Open User Profile',
}

export enum DepositPageStrings {
  DepositSubtitle = 'Því meira sem þú leggur inn, því meira getur þú tekið út.',
  EasyToSave = 'Það er einfalt að spara',
  CopyAccountNumber = 'Afritaðu reikningsnúmerið þitt með því að ýta á það.',
  OpenWebsite = 'Opna vef',
  SavingsStatus = 'Staða sparnaðarreiknings',
  AccountNumberCopied = 'Reikningsnúmer afritað',
  ThisIsSimple = 'Þetta er einfalt',
}

export enum FooterStrings {
  Savings = 'Sparnaður',
  FAQ = 'Algengar spurningar',
  About = 'Um Auði',
  LegalDisclaimers = 'Lagalegir fyrirvarar',
  CookieTerms = 'Vefkökuskilmálar',
  BusinessTerms = 'Viðskiptaskilmálar',
  TermAccountTerms = 'Skilmálar bundins reiknings',
  AudurEmail = 'audur@audur.is',
  AudurAddress = 'Katrínartúni 2, 105 Reykjavík',
  AudurPhone = '585 6500',
}

export enum LoginStrings {
  ContinueWithEID = 'Haltu áfram með rafrænum skilríkjum',
  ContinueWithESIM = 'Haltu áfram með Auðkennisappinu',
  ElectronicIdText = 'Auðkenningarbeiðni hefur verið send í símanúmerið þitt. Vinsamlega staðfestu innskráningu.',
  ESIMText = 'Opnaðu Auðkennisappið til að klára innskráningu.',
  SecurityNumber = 'Öryggisnúmer',
  UserToYoungText = 'Til að stofna reikning hjá Auði þarftu að vera minnst 13 ára.',
}

export enum SettingsStrings {
  SettingsSubtitle = 'Alltaf gott að hafa stillingarnar á hreinu',
}

export enum FutureAccountStrings {
  CardDescription = 'Verðtryggður sparnaðarreikningur sem er bundinn til 18 ára aldurs.',
  Congratulations = 'Húrra! Njóttu þess að sjá peningana vaxa.',
  CreateFutureAccount = 'Stofna grænan framtíðarreikning',
  FutureAccount = 'Grænn framtíðarreikningur',
  FutureAccountShort = 'Framtíðarreikningur',
  FutureAccounts = 'Grænir framtíðarreikningar',
  SelectChild = 'Veldu barn á þínu fjölskyldunúmeri sem þú vilt stofna reikning fyrir.',
  TooltipTitle = 'Grænn framtíðarreikningur',
  TooltipSubtitle = 'Grænn framtíðarreikningur hentar þeim vel sem vilja hefja sparnað barna og ungmenna og fá hæstu mögulegu verðtryggðu vextina.',
  TooltipBullet1 = 'Vextir á grænum framtíðarreikningum eru verðtryggðir og greiðast vextir út ásamt verðbótum í lok árs inn á reikninginn.',
  TooltipBullet2 = 'Hægt er að leggja inn á reikninginn þangað til eigandi reikningsins verður 15 ára. Eftir það er lokað á innlagnir.',
  TooltipBullet3 = 'Öll innstæðan losnar á 18 ára afmælisdegi reikningseiganda og er laus í mánuð. Eftir það er innstæðan bundin ótímabundið og panta þarf úttekt með þriggja mánaða fyrirvara.',
  TooltipBullet4 = 'Vextir á grænum framtíðarreikningum taka mið af vaxtastigi á fjármálamarkaði á hverjum tíma.',
  InputAmountText = 'Til að stofna grænan framtíðarreikning verður þú fyrst að millifæra pening af sparnaðarreikningi þínum hjá Auði. Eftir það er hægt að millifæra inn á hann úr hvaða íslenska banka sem er.',
  TermsDescription = 'Samningur um stofnun græns framtíðarreiknings hjá Auði/Kviku banka hf.',
  GetAppToCreateAccount = 'Til að stofna grænan framtíðarreikning þarftu að vera með Auðar appið',
  TransferFromSavingsToFuture = 'Sláðu inn upphæð til að millifæra á grænan framtíðarreikning af sparnaðarreikningi.\n\nMundu að þú getur líka millifært af reikningum í öðrum bönkum en svo lokar fyrir innlagnir við 15 ára aldur. Sjá nánar á audur.is.',
  TransferredToFutureAccount = 'Voru millifærðar á græna framtíðarreikninginn',
  InfoSubheader = 'Grænn framtíðarreikningur hentar þeim vel sem vilja hefja sparnað barna og ungmenna og fá hæstu mögulegu verðtryggðu vextina.',
  InfoIndexation = 'Vextir á grænum framtíðarreikningum eru verðtryggðir og greiðast vextir út ásamt verðbótum í lok árs inn á reikninginn.',
  InfoDeposits = 'Hægt er að leggja inn á reikninginn þangað til eigandi reikningsins verður 15 ára. Eftir það er lokað á innlagnir.',
  InfoClosing = 'Öll innstæðan losnar á 18 ára afmælisdegi reikningseiganda og er laus í mánuð. Eftir það er innstæðan bundin ótímabundið og panta þarf úttekt með þriggja mánaða fyrirvara.',
  InfoInterest = 'Vextir á grænum framtíðarreikningum taka mið af vaxtastigi á fjármálamarkaði á hverjum tíma.',
  AdTitle = 'Auður til framtíðar',
  AdDescription = 'Fyrir þau sem vilja hefja sparnað barna og ungmenna og fá hæstu mögulegu verðtryggðu vextina.',
  FutureAccountsBalance = 'Innstæða grænna framtíðarreikninga',
  CancelCreatingAnAccount = 'Viltu hætta við stofnun reiknings?',
  ChooseAmount = 'Veldu stofnupphæð sem verður millifærð af Sparnaðarreikningnum þínum yfir á framtíðarreikninginn.',
  TransferFromOtherBanks = 'Mundu að þú getur líka millifært af reikningum í öðrum bönkum en svo lokar fyrir innlagnir við 15 ára aldur. Sjá nánar á audur.is.',
}

export enum FutureAccountTermsStrings {
  Terms1 = 'Samningur um stofnun bundins græns framtíðarreiknings hjá Auði/Kviku banka hf.',
  Terms2 = 'Umsækjandi/lögráðamaður hefur sótt um að opna bundinn verðtryggðan framtíðarreikning hjá Auði (Kviku banka hf., kt. 540502-2930) fyrir hönd reikningseiganda. Reikningurinn er bundinn til 18 ára aldurs reikningseiganda.',
  Terms3 = 'Umsækjandi, f.h. reikningseiganda, og Kvika banki hf., kt. 540502-2930, gera með sér þennan samning um stofnun bundins framtíðarreiknings hjá Auði. Auður er vörumerki fyrir rafræna þjónustu um fjármál og er hluti af þeirri þjónustu sem Kvika býður viðskiptavinum upp á. Kvika er með viðskiptabankaleyfi skv. lögum um fjármálafyrirtæki. Um bundna græna framtíðarreikninga Auðar og samninginn gilda eftirfarandi skilmálar, auk almennra skilmála Auðar, eftir því sem við á, sem aðgengilegir eru á heimasíðu Auðar, audur.is, og almennra skilmála vegna viðskipta hjá Kviku banka hf. og aðgengilegir eru á heimasíðu Kviku, kvika.is.',
  Terms4 = 'Allar innstæður sem lagðar eru inn á framtíðarreikninga Auðar verða notaðar til að fjármagna eða endurfjármagna umhverfisvæn verkefni eins og þau eru skilgreind í grænni fjármálaumgjörð Kviku banka, sem er aðgengileg á heimasíðu bankans.',
  Terms5 = 'Umsækjandi gerir sér grein fyrir að innborganir á reikning, ásamt vöxtum og verðbótum, eru bundnar til 18 ára aldurs reikningseiganda. Hægt er að stofna framtíðarreikning til 17 ára aldurs reikningseiganda. Framangreint gildir óháð því hvort reikningseigandi stundi reglulegan sparnað eða ekki. ',
  Terms6 = 'Í einn mánuð (30 daga) frá 18 ára afmælisdegi reikningseiganda verður innstæða laus til útborgunar og er reikningseiganda einum, eða gildum umboðsmanni, heimilt að taka út af reikningnum en binst aftur að þeim tíma liðnum og verður frá hægt að taka út innstæðu með þriggja mánaða fyrirvara í samræmi við reglur Seðlabanka Íslands um verðtryggingu sparifjár og lánsfjár nr. 877/2018, sbr. 1. tl. 2. mr. 2. gr., sbr. reglur nr. 109/2019. Útborgun er háð því skilyrði að fullgildum persónuskilríkjum eða umboði reikningseiganda til úttektar sé framvísað, ásamt öðrum skilyrðum til úttektar. Umboðsmaður skal sanna á sér deili á sama hátt og reikningseigandi.',
  Terms7 = 'Á 18 ára afmælisdegi reikningseiganda missir lögráðamaður sýn á og umsjón með reikningi þar sem reikningseigandi öðlast fjárræði á þeim tímapunkti. Reikningseigandi þarf við 18 ára aldur að skrá sig inn með rafrænum skilríkjum, svara spurningum og undirrita viðskiptaskilmála til að öðlast umsjón með reikningi sínum.',
  Terms8 = 'Reikningurinn er verðtryggður grænn innlánsreikningur sem tekur mið af breytingum á vísitölu neysluverðs. Breytilegir vextir reiknast á innistæðu.',
  Terms9 = 'Vaxtatímabil reiknings er frá 1. janúar til 31. desember ár hvert, en vextir bætast við höfuðstól við lok hvers árs.',
  Terms10 = 'Verðbætur reiknast um hver mánaðarmót samkvæmt vísitölu neysluverðs á lægstu innistæðu reikningsins í síðasta almanaksmánuði og leggjast jafnframt við höfuðstól við næstu mánaðarmót.',
  Terms11 = 'Umsækjandi gerir sér grein fyrir því hvað felst í öllu framangreindu um binditíma, innborganir og úttektir.',
  Terms12 = 'Umsækjandi staðfestir að hafa kynnt sér og samþykkir skilmála þessa, almenna viðskiptaskilmála Kviku banka hf., almenna skilmála Auðar, og vaxtatöflu. Gildandi viðskiptaskilmálar Kviku banka hf., skilmálar Auðar og vaxtatafla á hverjum tíma teljast vera hluti samnings þessa. Þessir skilmálar ganga framar almennum skilmálum Auðar að því er varðar binditíma reikningsins, innborganir eða úttektir af reikningnum, auk ákvæða um vexti og verðbætur.',
  Terms13 = 'Umsækjandi veitir Kviku banka hf. heimild til vinnslu persónuupplýsinga sem nauðsynleg er til þess að framkvæma samning þennan. Tilgangur vinnslu persónuupplýsinganna er til þess að bankinn geti efnt skuldbindingar sínar samkvæmt samningi þessum og er geymslutími persónuupplýsinganna 7 ár frá lokum samnings skv. lögum um bókhald.',
  Terms14 = 'Með samþykki umsækjanda á skilmálum þessum samþykkir hann bókunaraðferðir bankans, t.d. varðandi vaxtareikning. Almennt er vaxtaárið 360 dagar. Vaxtatímabil miðast við 30 daga í mánuði, en upphafi og lok tímabils fer eftir tegund innlánsreiknings hverju sinni. Almennt bera innborganir vexti frá og með þeim tíma er fjármunir eignfærðir á reikning en ef reikningur er stofnaður um helgi eða eftir klukkan 21:00 á virkum degi er bókunardagur innborgunar næsti virki dagur. Síðasti vaxtadagur er dagurinn áður en úttekt er skuldfærð á reikning. Það ræðst af eðli og tegundum reikninga hvenær vextir leggjast við höfuðstól. Fjármagnstekjuskattur er skuldfærður við vaxtabókun.',
  Terms15 = 'Um stofnun reiknings gilda jafnframt lögræðislög nr. 71/1997, lög um aðgerðir gegn peningaþvætti og fjármögnun hryðjuverka nr. 140/2018 og reglur settar með stoð í þeim, ásamt öðrum viðeigandi réttarreglum hverju sinni.',
  Terms16 = 'Ef ofangreindum atriðum er ekki framfylgt getur umsækjandi orðið ábyrg/-ur gagnvart bankanum á tjóni, sem leiða kann af misnotkun reikningsins.',
}

export enum IndexedSavingsAccountTermsStrings {
  Terms2 = 'Umsækjandi hefur sótt um að opna verðtryggðan reikning hjá Auði (Kviku banka hf., kt. 540502-2930). Innstæða reikningsins er bundin og er úttekt aðeins heimil með 90 daga fyrirvara. Innstæða reikningsins er verðtryggð í samræmi við reglur Seðlabanka Íslands um verðtryggingu sparifjár hverju sinni.',
  Terms3 = 'Innborganir bera vexti samkvæmt vaxtatöflu frá og með næsta degi eftir innborgun og verðbætur eru færðar í lok hvers mánaðar. Vextir og verðbætur eru greiddar mánaðarlega. Vegna 90 daga fyrirvara um úttekt gerir umsækjandi sér grein fyrir að reikningurinn er ekki greiðslureikningur í skilningi laga nr. 5/2023 um greiðslureikninga. Auði er heimilt að breyta skilmálum þessum án fyrirvara með tilkynningu til reikningseiganda.',
  Terms4 = 'Umsækjandi staðfestir að hafa kynnt sér og samþykkt almenna viðskiptaskilmála Kviku banka hf., almenna skilmála Auðar og vaxtatöflu. Gildandi viðskiptaskilmálar Kviku banka hf., skilmálar Auðar og vaxtatafla á hverjum tíma teljast vera hluti samnings þessa. Þessir skilmálar ganga framar almennum skilmálum Auðar að því er varðar binditíma reikningsins, innborganir eða úttektir af reikningnum. Framangreinda skilmála og vaxtatöflu má finna á heimasíðu Kviku banka hf., www.kvika.is, og á heimasíðu Auðar, www.audur.is.',
}

export enum ChildInsuranceStrings {
  IsYourChildInsured = 'Er barnið þitt tryggt?',
  AddChildInsurance = 'Bæta við barnatryggingu',
  AddChildInsuranceShort = 'Bæta við',
  CompleteInsuranceApplication = 'Ganga frá barnatryggingu hjá TM',
  CompleteInsuranceApplicationShort = 'Ganga frá á vef TM',
  TMChildInsurance = 'Barnatrygging TM',
  ViewInsurance = 'Skoða tryggingu',
  ChildInsurance = 'Barnatrygging',
  ChildInsuranceInfo = 'Barnatrygging TM er tilvalin fyrir fjölskyldufólk og veitir góða vernd gegn afleiðingum slysa og sjúkdóma sem börn kunna að verða fyrir.\n\nÞú getur nálgast nánari upplýsingar um trygginguna þína í TM appinu eða undir „Mitt öryggi“ á heimasíðu TM.',
  ViewOnTMWebsite = 'Skoða á vef TM',
  InProgress = 'Umsókn í vinnslu',
  InsuranceInProgress = 'Trygging í vinnslu',
  InProgressInfo = 'Við erum að yfirfara umsóknina þína. Við látum þig vita ef við þurfum frekari upplýsingar og sömuleiðis þegar umsóknin hefur verið samþykkt.',
  Incomplete = 'Þú átt eftir að klára umsóknina',
  IncompleteInfo = 'Þú getur gengið frá umsókninni þinni eða hætt við á vef TM.',
  Rejected = 'Umsókn hafnað',
  RejectedInfo = 'Því miður hefur umsókn þinni um barnatryggingu verið hafnað. Þú átt von á bréfi/pósti frá okkur þar sem niðurstaðan verður skýrð nánar.',
  OpenApplicationOnTMWebsite = 'Opna umsókn á vef TM',
  AddChildInsuranceAnnouncement = 'Nú geturðu bætt við barnatryggingu þegar þú stofnar Grænan framtíðarreikning.\n\nEf þú átt Grænan framtíðarreikning nú þegar er hægt að bæta barnatryggingu við. Viðskiptavinum Auðar býðst barnatrygging TM á 20% afslætti. Tryggingin býður örorkubætur ef slys eða sjúkdóma ber að garði.',
  ViewInTMApp = 'Skoða í TM appinu',
}

export enum ConfirmInformationStrings {
  ConfirmInformation = 'Staðfesta upplýsingar',
  ConfirmInformationText = 'Auður verður, samkvæmt lögum, að uppfæra reglubundið upplýsingar um sína viðskiptavini. Því þarft þú að staðfesta eða uppfæra upplýsingarnar sem koma hér á eftir.',
  AnswerLater = 'Svara seinna',
}

export enum PrintTransactionsIcelandic {
  AccountStatement = 'Reikningsyfirlit',
  AccountBalance = 'Staða reiknings',
  Name = 'Nafn',
  Address = 'Heimilisfang',
  SocialSecurityNumber = 'Kennitala',
  BankAccountNumber = 'Reikningsnúmer',
  Retrieved = 'Sótt',
  Date = 'Dagsetning',
  Description = 'Skýring',
  Amount = 'Upphæð',
  Balance = 'Staða',
  Retrieve = 'Sækja',
}

export enum PrintTransactionsEnglish {
  AccountStatement = 'Account statement',
  AccountBalance = 'Balance',
  Name = 'Name',
  Address = 'Address',
  SocialSecurityNumber = ' ID number (kennitala)',
  BankAccountNumber = 'Bank account',
  Retrieved = 'Retrieved',
  Date = 'Date',
  Description = 'Description',
  Amount = 'Amount',
  Balance = 'Balance',
}

export enum DisruptionModeStrings {
  SmallProblem = 'Smá vesen',
  DisruptionModeText = 'Vegna tæknilegra örðugleika gætu sumar aðgerðir ekki virkað sem skildi. Við erum að vinna í málinu.',
}

export enum ExtraSavingsAccountsStrings {
  SavingsAccount = 'Óbundinn reikningur',
  CreateSavingsAccount = 'Stofna óbundinn reikning',
  NameSavingsAccount = 'Gefðu reikningnum eitthvað frábært nafn sem lýsir honum vel.',
  NameOfAccount = 'Nafn á reikning',
  NameOfAccountInvalid = 'Nafn á reikning er ógilt',
  SavingsAccounts = 'Óbundnir reikningar',
  InfoDrawerSubtitle = 'Þægileg leið til að leggja pening til hliðar og safna fyrir einhverju ákveðnu!',
  InfoDrawerInterests = 'Vextir greiddir út mánaðarlega og þú nýtur ágóðans.',
  InfoDrawerYearlyInterests = 'Vextir eru gefnir upp á ársgrundvelli.',
  InfoDrawerNonTerm = 'Innstæðan er alltaf laus til úttektar.',
  InfoDrawerNonIndexedInterests = 'Óverðtryggðir breytilegir vextir sem taka mið af vaxtastigi á fjármálamarkaði á hverjum tíma.',
  Congratulations = 'Til hamingju með nýja sparnaðarreikninginn',
  CongratulationsShort = 'Til hamingju með nýja sparnaðar-',
  Account = 'reikninginn',
  ChooseAccount = 'Inn á hvaða reikning vilt þú millifæra?',
  NextStep = 'Næsta skref',
  AmountToTransfer = 'Hvaða upphæð viltu millifæra?',
  SourceAccount = 'Úttektarreikningur',
  TransferredTo = 'Lagt inn á',
  RenameAccount = 'Endurnefna reikning',
  DeleteAccount = 'Eyða reikning',
  AccountWillBeDeleted = 'Reikningnum verður eytt',
  WhatAccountToTransferFrom = 'Af hvaða reikning vilt þú millifæra?',
  AskToDestroyAccount = 'Viltu biðja Auði um að eyðlileggja reikninginn?',
  DepositAmount = 'Innstæða ásamt áunnum vöxtum, að frádregnum fjármagnstekjuskatti, verður lögð inn á Sparnaðarreikninginn þinn.',
  CanTakeAWhileToCompleteShort = 'Athugaðu að það getur tekið smástund að ganga frá þessu.',
  RequestReceived = 'Auður hefur móttekið beiðni þína um að eyða reikningnum.',
  CanTakeAWhileToCompleteDestroyLong = 'Reikningurinn hættir strax að birtast í yfirlitinu, en það gæti tekið smástund fyrir peninginn að berast inn á Sparnaðarreikninginn.',
  TransferSuccessful = 'Voru millifærðar á eftirfarandi reikning',
  ChooseSourceAccount = 'Veldu úttektarreikning',
  ChooseDestinationAccount = 'Veldu reikning til þess að leggja inn á',
  ChooseAmount = 'Veldu upphæð',
  MaxAllowedAccountsTitle = 'Þú hefur náð hámarkinu!',
  MaxAllowedAccountsDescription = 'Aðeins er hægt að vera með 10 óbundna reikninga opna í einu. Til þess að geta stofnað nýjan reikning verður þú að eyða einhverjum af núverandi reikningum. Mundu líka að þú getur endurskírt núverandi reikninga.',
  AdDescription = 'Erum við ekki öll að spara fyrir ýmsu? Nú býður Auður upp á að stofna fleiri en einn óbundinn reikning sem bera að sjálfsögðu frábæru vexti Auðar sem greiðast út mánaðarlega.',
  AdTitle = 'Margir óbundnir reikningar',
}

export enum IndexedSavingsAccountsStrings {
  IndexedSavingsAccount = 'Verðtryggður reikningur',
  IndexedSavingsAccountDescription = 'Þessi reikningur hentar þeim sem geta bundið peningana sína í að minnsta kosti 90 daga og fá verðtryggða vexti.',
  InfoDrawerAmount = 'Þú velur þá upphæð sem á að losna eftir 90 daga.',
  InfoDrawerIndexation = 'Innstæðan er verðtryggð svo hún getur hækkað eða lækkað í takt við verðbólgu eða verðhjöðnun.',
  InfoDrawerInterestsMonthly = 'Vextir greiðast út mánaðarlega ásamt verðbótum.',
  InfoDrawerInterests = 'Vextir taka mið af vaxtastigi á fjármálamarkaði á hverjum tíma.',
  CreateIndexedSavingsAccount = 'Stofna verðtryggðan reikning',
  CreateIndexedSavingsAccountTermsInfo = 'Samningur um stofnun verðtryggs sparnaðarreiknings hjá Auði/Kviku banka hf.',
  Congratulations = 'Til hamingju með nýja verðtryggða reikninginn',
  AvailableBalance = 'Ráðstöfun',
  TransferMoneyInfo = 'Mundu að þú þarft að hinkra í 90 daga þar til upphæðin verður laus.',
  PendingTransactions = 'Færslur í bið',
  TransactionOrderDone = 'Pöntun á millifærslu lokið',
  ToBeTransferred = 'Verða millifærðar á eftirfarandi reikning eftir 90 daga',
  DateOfTransfer = 'Dagsetning millifærslu',
  DateOfOrder = 'Dagsetning pöntunar',
  CancelTransfer = 'Hætta við færslu',
  ConfirmCancelTransaction = 'Ertu viss um að þú viljir hætta við eftirfarandi millifærslu?',
  MaxAllowedAccountsDescription = 'Það er aðeins hægt að vera með að hámarki 10 verðtryggða reikninga.',
  IndexedSavingsAccounts = 'Verðtryggðir reikningar',
  WaitToTransferMoney = 'Athugaðu að þessi peningur verður laus til ráðstöfunar einum til tveimur virkum dögum eftir innlögn.',
  NotEnoughAvailableAmount = 'Engin ráðstöfun',
  AvailableAmountToUse = 'Til ráðstöfunar',
  AdDescription = 'Nýjasta vara Auðar er Verðtryggður reikningur. Innstæðan er verðtryggð og eru vextir og verðbætur greiddar út mánaðarlega. Þú pantar svo úttekt og færð hana greidda út 90 dögum síðar.',
}

export enum SharedAccountsString {
  PowerOfAttorney = 'Umboð',
  GiveAndRevoke = 'Hér getur þú veitt og afturkallað umboð sem felur í sér skoðunaraðgang að reikningum þínum hjá Auði ásamt því að geta séð umboð sem aðrir notendur veita þér.',
  MustBeCustomers = 'Athugaðu að umboðshafar verða að vera í viðskiptum við Auði til þess að sjá þá reikninga sem þú veitir þeim aðgang að.',
  AccountsYouHaveShared = 'Umboð sem þú hefur veitt',
  AccountsThatHaveBeenSharedWithYou = 'Umboð sem þér hafa verið veitt',
  YouHaveNotSharedAnyAccounts = 'Þú hefur ekki veitt nein umboð.',
  NoSharedAccounts = 'Ekkert umboð.',
  ShareYourAccounts = 'Veita umboð',
  InputSSN = 'Sláðu inn kennitölu þess sem þú vilt veita umboð.',
  SSN = 'Kennitala umboðshafa',
  ChooseAccounts = 'Veldu þá reikninga sem þú vilt veita skoðunaraðgang að.',
  ChooseAllAccounts = 'Velja alla reikninga',
  ShareAccount = 'Umboðsveiting',
  DocumentSigning = 'Undirritun fer fram með rafrænum skilríkjum.',
  OpenDocument = 'Skoða skjal',
  AccountsShared = 'Veitt umboð',
  PersonSharingYourAccount = 'Umboðshafi',
  ViewAccess = 'Skoðunaraðgangur',
  ChangeAccess = 'Breyta aðgengi',
  ShareNewAccount = 'Veita nýtt umboð',
  RevokeSharingAccount = 'Afturköllun umboðs',
  ChosenAccounts = 'Valdir reikningar',
  ShareAndRevokeSharingAccounts = 'Umboðsveiting og afturköllun umboðs',
  AccountOwner = 'Eigandi reiknings',
  GivenViewAccess = 'Þér hefur verið veittur skoðunaraðgangur að þessum reikning. Það þýðir að þú getur séð stöðu og færslur á reikningnum en þú getur ekki framkvæmt neinar aðgerðir.',
  YourAccess = 'Þitt aðgengi',
  PersonSharingAccount = 'Umboðsveitandi',
  WhatAccountsToDisplay = 'Hér getur þú valið hvaða reikninga þú vilt sjá í yfirlitinu þínu.',
  IncorrectSSN = 'Kennitala ekki rétt, reyndu aftur.',
  SaveTogether = 'Sparið saman!',
  AdDescription = 'Nú er hægt að deila sparnaðarreikningum með öðrum. Ef þú ferð í „Stillingar” og velur „Umboð” geturðu veitt öðrum notanda skoðunaraðgang að völdum sparnaðarreikningi. Fyrir vikið verður sparnaðarferðalagið enn skemmtilegra!',
}

export enum MortgageStrings {
  // Top bar
  RealEstate = 'Fasteign',

  // Title
  Mortgage = 'Húsnæðislán',

  // Highlights 1
  RemainingBalance = 'Eftirstöðvar',
  Interest = 'Vextir',
  Variable = 'Breytilegir',
  NextPayment = 'Næsta afborgun',

  // Highlights 2
  MortgageOverview = 'Yfirlit',
  LoanDevelopment = 'Þróun láns',

  // Highlights 3
  PathToPayment = 'Greiðsluleið',
  PaymentSchedule = 'Greiðsluáætlun',
  PaymentMethod = 'Greiðslumáti',
  PaymentDate = 'Greiðsludagur',
  PaymentDays = 'Greiddir gjalddagar',
  MortgageNumber = 'Lánanúmer',
  OriginalLoanAmount = 'Upphafleg lánaupphæð',

  // Table headers
  LastPayments = 'Síðustu greiðslur',
  Period = 'Tímabil',

  // table columns
  PaidOnDate = 'Greitt dags.',
  PaymentDay = 'Gjalddagi',
  Type = 'Gerð',
  PaidOnPrincipal = 'Greitt á höfuðstól',

  // Mortgage payment methods
  Payment = 'Afborgun',
  Deposit = 'Innborgun',
  PrivatePension = 'Séreignasparnaður',
}

export enum GeneralModalStrings {
  CancelModalTitle = 'Því miður þurfum við að stoppa hér',
  CancelModalDescriptionUSA = 'Auður getur lögum samkvæmt ekki skráð í viðskipti lögaðila þar sem stjórnandi er bandarískur aðili í skilningi FATCA.',
  CancelModalDescriptionNotOwner = 'Auður getur því miður ekki skráð lögaðila í viðskipti sem er ekki réttur eigandi þeirra fjármuna sem færðir eru bankanum.',
}
