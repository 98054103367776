import { useEffect, useState } from 'react';

import { DisruptionModeStrings } from 'utils/strings';

import { DisruptionTypeEnum } from '@kvika/api-types';
import { decodeBase64String } from '@kvika/string-utils';
import { useAppContext } from '../../store/AppContext';
import { ActionType } from '../../store/Reducers';
import AudurModal from '../../components/modal/AudurModal';

const DisruptionModal = () => {
  const { state, dispatch } = useAppContext();
  const { serviceStatusMode } = state;
  const [isOpen, setIsOpen] = useState(false);
  const { showAfter, serviceStatus } = serviceStatusMode;
  const message = serviceStatusMode.message
    ? decodeBase64String(serviceStatusMode.message)
    : DisruptionModeStrings.DisruptionModeText;
  const addMinutes = (numOfMinutes: number, date: Date) => {
    date.setMinutes(date.getMinutes() + numOfMinutes);
    return date;
  };

  useEffect(() => {
    if (serviceStatus === DisruptionTypeEnum.EXTERNAL) {
      // Only show modal and update when it should next be shown if
      // 1) we've never shown it before or
      // 2) we have shown it but it's time for it to be shown again
      if (!showAfter || (showAfter && showAfter <= new Date())) {
        setIsOpen(true);
        dispatch({
          type: ActionType.UpdateServiceStatusMode,
          payload: { showAfter: addMinutes(15, new Date()) },
        });
      }
    }
  }, [dispatch, serviceStatus, showAfter]);

  return (
    <AudurModal
      title={DisruptionModeStrings.SmallProblem}
      isOpen={isOpen}
      handleClose={() => {
        setIsOpen(false);
      }}
    >
      {message}
    </AudurModal>
  );
};

export default DisruptionModal;
