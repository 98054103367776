import styled from 'styled-components';
import { GridRem } from '../Styleguide';

export const StyledPageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 ${GridRem.Gridx32} ${GridRem.Gridx32} ${GridRem.Gridx32};

  @media (max-width: 30em) {
    margin: 0 ${GridRem.Gridx8} ${GridRem.Gridx8} ${GridRem.Gridx8};
  }
`;
