import * as prismic from '@prismicio/client';

import {
  AudurLegal,
  AudurLegalConnectionConnection,
  AudurProductConnectionConnection,
  AudurMarketInterestRatesConnectionConnection,
  FaqCategoryConnectionConnection,
  FaqCategory,
  Query,
  Maybe,
  AudurContentPage,
  AudurContentPageConnectionConnection,
  AudurFooterConnectionConnection,
  CreateAccountPageConnectionConnection,
  AudurHeaderConnectionConnection,
  CreateAccountCardsConnectionConnection,
} from './PrismicTypes';
import { prismicConstants } from './PrismicUtils';

const { refApiUrl, apiLocale, apiDefaultLocale, gqlApiUrl } = prismicConstants;

enum StatusCode {
  OK = 200,
}

type PreviewData = {
  ref: string;
};

interface Variables {
  uId?: string;
  lang?: string;
}

type APIOptions = {
  previewData?: PreviewData;
  variables?: Variables;
};

export class AudurPrismicClient {
  private prismicClient: prismic.Client;

  constructor(apiToken: string) {
    this.prismicClient = prismic.createClient(refApiUrl, {
      accessToken: apiToken,
    });
  }

  private async fetchAPI(query: string, { previewData, variables }: APIOptions = {}): Promise<Query> {
    // see: https://github.com/birkir/gatsby-source-prismic-graphql/commit/57e2940342f724ef21bc951b987a409fbc2d9d82
    const cleanQuery = query
      .replace(/#.*\n/g, '')
      .replace(/\s+/g, ' ')
      .replace(/\s?\{\s?/g, '{')
      .replace(/\s?\}\s?/g, '}')
      .replace(/\s?:\s?/g, ':')
      .replace(/\s?\(\s?/g, '(')
      .replace(/\s?\)\s?/g, ')');

    const { accessToken } = this.prismicClient;
    const requestHeaders: HeadersInit = new Headers();
    const masterRef = await this.prismicClient.getMasterRef();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Prismic-Ref', previewData?.ref || masterRef.ref);
    requestHeaders.set('Accept-Language', apiLocale ?? apiDefaultLocale);
    requestHeaders.set('Authorization', `Token ${accessToken}`);

    const res = await fetch(
      `${gqlApiUrl}?query=${encodeURIComponent(cleanQuery)}&variables=${encodeURIComponent(JSON.stringify(variables))}`,
      {
        headers: requestHeaders,
      }
    );

    if (res.status !== StatusCode.OK) {
      throw new Error('Prismic request failed with a non-200 response');
    }

    const json = await res.json();
    if (json.errors) {
      throw new Error('Error in Prismic JSON response');
    }
    return json.data;
  }

  public getPrismicClient(): prismic.Client {
    return this.prismicClient;
  }

  public async getMasterRef(): Promise<string> {
    const masterRef = await this.prismicClient.getMasterRef();
    return masterRef.ref;
  }

  /**
   *
   * @param APIOptions: { apiToken, previewData, variables }
   * @returns Promise<Maybe<AudurLegal>>
   */
  public async getAudurLegalByUid({ previewData, variables }: APIOptions = {}): Promise<Maybe<AudurLegal>> {
    const data = await this.fetchAPI(
      `
      query getAudurLegalByUID($uId: String!, $lang: String!) {
        audurLegal(uid: $uId, lang: $lang) {
          title
          content
          showcustomcontent
          body {
            __typename
            ... on AudurLegalBodyMeta_tags {
              type
              primary {
                metaTitle
                metaDescription
                metaImage
              }
            }
          }
          _meta {
            id
            uid
            lang
            type
          }
        }
      }
      
    `,
      {
        previewData,
        variables,
      }
    );
    return data.audurLegal;
  }

  /**
   *
   * @param APIOptions: { apiToken, previewData, variables }
   * @returns Promise<Maybe<AudurLegalConnectionConnection>>
   */
  public async getAllAudurLegals({ previewData, variables }: APIOptions = {}): Promise<
    Maybe<AudurLegalConnectionConnection>
  > {
    const data = await this.fetchAPI(
      `query getAllAudurLegals($lang: String!) {
        allAudurLegals(lang: $lang) {
          edges {
            node {
              _meta {
                uid
                lang
              }
            }
          }
        }
      }`,
      {
        previewData,
        variables,
      }
    );
    return data.allAudurLegals;
  }

  /**
   *
   * @param APIOptions: { apiToken, previewData, variables }
   * @returns Promise<Maybe<AudurProductConnectionConnection>>
   */
  public async getAllProducts({ previewData, variables }: APIOptions = {}): Promise<
    Maybe<AudurProductConnectionConnection>
  > {
    const data = await this.fetchAPI(
      `query getAllAudurProducts($lang: String!) {
        allAudurProducts(lang: $lang) {
          edges {
            node {
              id
              type
              term
              interestRate
              indexation
              productName
              depositAvailable
              interestPayoutTerm
            }
          }
        }
      }`,
      {
        previewData,
        variables,
      }
    );
    return data.allAudurProducts;
  }

  /**
   *
   * @param APIOptions: { apiToken, previewData, variables }
   * @returns Promise<Maybe<AudurProductConnectionConnection>>
   */
  public async getFooter({ previewData, variables }: APIOptions = {}): Promise<Maybe<AudurFooterConnectionConnection>> {
    const data = await this.fetchAPI(
      `query getFooter($lang: String!) {
        allAudurFooters(lang: $lang) {
          edges {
            node {
              image
              description
              phone_number {
                ... on AudurLink {
                  linkUrl
                  linkTitle
                  linkAlt
                  linkImage
                  linkStyle
                }
              }
              email {
                ... on AudurLink {
                  linkUrl
                  linkTitle
                  linkAlt
                  linkImage
                  linkStyle
                }
              }
              link {
                ... on AudurLink {
                  linkUrl
                  linkTitle
                  linkAlt
                  linkImage
                  linkStyle
                }
              }
              button {
                ... on AudurLink {
                  linkUrl
                  linkTitle
                  linkAlt
                  linkImage
                  linkStyle
                }
              }
              first_column {
                first_column_link {
                  ... on AudurLink {
                    linkUrl
                    linkTitle
                    linkAlt
                    linkImage
                    linkStyle
                  }
                }
              }
              second_column {
                second_column_link {
                  ... on AudurLink {
                    linkUrl
                    linkTitle
                    linkAlt
                    linkImage
                    linkStyle
                  }
                }
              }
            }
          }
        }
      }
      `,
      {
        previewData,
        variables,
      }
    );
    return data.allAudurFooters;
  }

  /**
   *
   * @param APIOptions: { apiToken, previewData, variables }
   * @returns Promise<Maybe<AudurMarketInterestRatesConnectionConnection>>
   */
  public async getMarketInterestRates({ previewData, variables }: APIOptions = {}): Promise<
    Maybe<AudurMarketInterestRatesConnectionConnection>
  > {
    const data = await this.fetchAPI(
      `query getAllMarketInterestRates {
        allAudurMarketInterestRatess {
          edges {
            node {
              companyMarketInterestRates {
                lowDepositRate
                midDepositRate
                midToHighDepositRate
                highDepositRate
              }
              savingsMarketInterestRates {
                lowDepositRate
                midDepositRate
                midToHighDepositRate
                highDepositRate
              }
              termDepositMarketInterestRates {
                threeMonthTermRate
                sixMonthTermRate
                twelveMonthTermRate
              }
              futureMarketInterestRates {
                averageMarketFutureRate
              }
              inflationRateGoal
            }
          }
        }
      }
      `,
      {
        previewData,
        variables,
      }
    );
    return data.allAudurMarketInterestRatess;
  }

  /**
   *
   * @param APIOptions: { apiToken, previewData, variables }
   * @returns Promise<Maybe<AudurContentPage>>
   */
  public async getAudurContentPageByUid({ previewData, variables }: APIOptions = {}): Promise<Maybe<AudurContentPage>> {
    const data = await this.fetchAPI(
      `query getContentPage($uId: String!, $lang: String!) {
        audurContentPage(uid: $uId, lang: $lang) {
          title
          pageLayout
          body {
            __typename
            ... on AudurContentPageBodyMeta_tags {
              type
              primary {
                metaTitle
                metaDescription
                metaImage
              }
            }
          }
          slices {
            __typename
            ... on AudurContentPageSlicesInteresttable {
              type
              fields {
                bank_account_type
                annual_interest_rate
                interest_payment_frequency
                indexation
                availability
                bank_account_type_slug
                bank_account_term_slug
                interest_on_an_annual_basis
              }
            }
          }
          editorialContentGroup {
            contentPageEditorial {
              __typename
              ... on AudurEditorialContent {
                title
                description
                contentWidth
                contentAlignment
                contentType
                contentStyle
                image
                imageWidth
                imageHeight
                lottieImageName
                linkGroup {
                  editorialContentLink {
                    __typename
                    ... on AudurLink {
                      linkUrl
                      linkTitle
                      linkAlt
                      linkImage
                      linkStyle
                    }
                  }
                }
              }
            }
          }
          _meta {
            uid
            type
            lang
          }
        }
      }
    `,
      {
        previewData,
        variables,
      }
    );
    return data.audurContentPage;
  }

  /**
   *
   * @param APIOptions: { apiToken, previewData, variables }
   * @returns Promise<Maybe<AudurLegal>>
   */
  public async getAllAudurContentPages({ previewData }: APIOptions = {}): Promise<
    Maybe<AudurContentPageConnectionConnection>
  > {
    const data = await this.fetchAPI(
      `
      query {
        allAudurContentPages {
          edges {
            node {
              title
              pageLayout
              editorialContentGroup {
                contentPageEditorial {
                  __typename
                  ... on AudurEditorialContent {
                    title
                    description
                    contentWidth
                    contentAlignment
                    contentType
                    contentStyle
                    image
                    imageWidth
                    imageHeight
                    lottieImageName
                    linkGroup {
                      editorialContentLink {
                        __typename
                        ... on AudurLink {
                          linkUrl
                          linkTitle
                          linkAlt
                          linkImage
                          linkStyle
                        }
                      }
                    }
                  }
                }
              }
              _meta {
                uid
                type
                lang
              }
            }
          }
        }
      }
      
        `,
      {
        previewData,
      }
    );
    return data.allAudurContentPages;
  }

  /*
   * @param APIOptions: { previewData, variables }
   * @returns Promise<Maybe<FaqCategoryConnectionConnection>>
   */
  public async getAllFAQCategories({ previewData, variables }: APIOptions = {}): Promise<
    Maybe<FaqCategoryConnectionConnection>
  > {
    const data = await this.fetchAPI(
      `query getAllFAQCategories($lang: String!) {
        allFaqCategorys(lang: $lang) {
          edges {
            node {
              title
              image
              _meta {
                uid
                lang
              }
            }
          }
        }
      }`,
      {
        previewData,
        variables,
      }
    );
    return data.allFaqCategorys;
  }

  /*
   * @param APIOptions: { previewData, variables }
   * @returns Promise<Maybe<FaqCategory>>
   */
  public async getFAQCategoryByUid({ previewData, variables }: APIOptions = {}): Promise<Maybe<FaqCategory>> {
    const data = await this.fetchAPI(
      `query getFAQCategoryByUID($uId: String!, $lang: String!) {
        faqCategory(uid: $uId, lang: $lang) {
          title
          image
          faqItems {
            faqItemQuestion
            faqItemAnswer
          }
          body {
            __typename
            ... on FaqCategoryBodyMeta_tags {
              type
              primary {
                metaTitle
                metaDescription
                metaImage
              }
            }
          }
          _meta {
            id
            uid
            lang
            type
          }
        }
      }
      `,
      {
        previewData,
        variables,
      }
    );
    return data.faqCategory;
  }

  /**
   *
   * @param APIOptions: { previewData, variables }
   * @returns Promise<Maybe<CreateAccountPageConnectionConnection>>
   */
  public async getCreateAccountPage({ previewData, variables }: APIOptions = {}): Promise<
    Maybe<CreateAccountPageConnectionConnection>
  > {
    const data = await this.fetchAPI(
      ` query getCreateAccountPage($lang: String!) {
          allCreateAccountPages(lang: $lang) {
            edges {
              node {
                title
                description
                button {
                  _linkType
                  ... on AudurLink {
                    linkUrl
                    linkTitle
                    linkAlt
                    linkImage
                    linkStyle
                  }
                }
                group {
                  item {
                    ... on CreateAccountItem {
                      image
                      title
                    }
                  }
                }
              }
            }
          }
        }
        
        `,
      {
        previewData,
        variables,
      }
    );
    return data.allCreateAccountPages;
  }

  /**
   *
   * @param APIOptions: { previewData, variables }
   * @returns Promise<Maybe<AudurHeaderConnectionConnection>>
   */
  public async getHeader({ previewData, variables }: APIOptions = {}): Promise<Maybe<AudurHeaderConnectionConnection>> {
    const data = await this.fetchAPI(
      ` query getHeader($lang: String!) {
      allAudurHeaders(lang: $lang) {
        edges {
          node {
            image
            link_group {
              link {
                _linkType
                ... on AudurLink {
                  linkUrl
                  linkTitle
                  linkAlt
                  linkImage
                  linkStyle
                }
              }
            }
            button_group {
              button {
                _linkType
                ... on AudurLink {
                  linkUrl
                  linkTitle
                  linkAlt
                  linkImage
                  linkStyle
                }
              }
            }
          }
        }
      }
    }
      `,
      {
        previewData,
        variables,
      }
    );
    return data.allAudurHeaders;
  }

  public async getCreateAccountCardsInfo({
    previewData,
    variables,
  }: APIOptions = {}): Promise<CreateAccountCardsConnectionConnection> {
    const data = await this.fetchAPI(
      ` query getCreateAccountCardsInfo($lang: String!) {
          allCreate_account_cardss(lang: $lang) {
            edges{
              node{
                body{
                  __typename
                  ... on Create_account_cardsBodyAccount_card_info {
                    type
                    primary {
                      account_type
                      interest_rate
                      interest_rate_end_value
                      description
                    }
                    fields{
                      list_item
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        previewData,
        variables,
      }
    );
    return data.allCreate_account_cardss;
  }
}
export default AudurPrismicClient;
