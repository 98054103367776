const MultiselectCheckBoxFilled = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="6" fill="#4CC97F" />
    <path
      d="M17.2853 7.28564L8.64244 15.9285L4.71387 11.9999"
      stroke="#252B39"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export default MultiselectCheckBoxFilled;
