export type ExternalBankInfo = {
  bankName: string;
  bankNameInSentence: string;
  bankUrl: string;
};

export enum ExternalBank {
  Landsbankinn = '01',
  ArionBanki = '03',
  IslandsBanki = '05',
  Kvika = '07',
  Sparisjodir = '11',
  Indo = '22',
}
