import { toIskCurrencyString, toPercentageString } from '@kvika/string-utils';
import {
  differenceInDays,
  getAnnualInterestRate,
  getIsFutureAccount,
  getIsIndexedSavings,
  getIsSavings,
  getIsTermDeposit,
  getYoungestUser,
} from '@kvika/audur-utils';
import { AccountSchema, AddressSchema } from '@kvika/audur-api-types';
import {
  FutureAccountStrings,
  GeneralStrings,
  IndexedSavingsAccountsStrings,
  TermAccountStrings,
} from './StringConstants';
import { ExternalBank, ExternalBankInfo } from './Types';

/**
 * Gets Auður interest text description for term deposit accounts with params months and interestRate
 * @param months
 * @param interestRate
 */
export const getAudurTermDepositInterestText = (months: number, interestRate: number): string => {
  return `Innlánsvextir Auðar á bundnum ${months} mánaða reikningi eru ${toPercentageString(
    interestRate
  )} á ársgrundvelli og eru áfallnir vextir greiddir mánaðarlega. Vaxtakjör eru háð ákvörðun Auðar hverju sinni og taka mið af vaxtastigi á fjármálamarkaði og gilda þeir vextir sem eru í gildi á stofndegi reikningsins út allan binditímann. Bundnir reikningar Auðar eru óverðtryggðir.`;
};

/**
 * Gets average market interest rates for future accounts according to Aurbjorg.is
 */
export const getMarketFutureAccountInterestText = (interestRate: number): string => {
  return `Meðalvextir á sambærilegum innlánsreikningum eru ${toPercentageString(
    interestRate,
    2
  )} skv. Aurbjörgu (www.aurbjorg.is).`;
};

/**
 * Gets average market interest rates for company accounts according to Aurbjorg.is
 */
export const getMarketCompanyAccountInterestText = (interestRate: number): string => {
  return `Meðalvextir á sambærilegum innlánsreikningum eru ${toPercentageString(interestRate, 2)} skv. vaxtatöflu.`;
};

/**
 * Gets the static Auður interest rates for savings accounts
 */
export const getAudurSavingsAccountInterestText = (interestRate: number): string => {
  return `Óbundinn sparnaðarreikningur Auðar er óverðtryggður með breytilegum vöxtum. Sparnaðarreikningurinn ber nú ${toPercentageString(
    interestRate,
    2
  )} vexti og eru áfallnir vextir greiddir mánaðarlega sem jafngildir ${toPercentageString(
    getAnnualInterestRate(interestRate),
    2
  )} vöxtum á ársgrundvelli. Vaxtakjör eru háð ákvörðun Auðar hverju sinni og taka mið af vaxtastigi á fjármálamarkaði.`;
};

/**
 * Gets the Auður interest rates for future accounts
 */
export const getAudurFutureAccountInterestText = (interestRate: number): string => {
  return `Grænn framtíðarreikningur Auðar er verðtryggður með breytilegum vöxtum. Hann ber nú ${toPercentageString(
    interestRate,
    2
  )} vexti og eru vextir greiddir út í lok árs. Vaxtakjör eru háð ákvörðun Auðar hverju sinni og taka mið af vaxtastigi á fjármálamarkaði.`;
};

/**
 * Gets the company account interest rates for Auður
 */
export const getAudurCompanyAccountInterestText = (interestRate: number): string => {
  return `Fyrirtækjareikningur Auðar er óverðtryggður með breytilegum vöxtum. Hann ber nú ${toPercentageString(
    interestRate,
    2
  )} vexti og greiðast þeir út í lok árs. Vaxtakjör eru háð ákvörðun Auðar hverju sinni og taka mið af vaxtastigi á fjármálamarkaði.`;
};

/**
 * Gets the projected inflation rate text according to Sedlabanki
 */
export const getProjectedInflationRateText = (inflationRate: number): string => {
  return `Til einföldunar á útreikningi á áætlaðri ávöxtun á grænum framtíðarreikningi er miðað við ${toPercentageString(
    inflationRate,
    2
  )} árlegri ársverðbólgu sem er verðbólgumarkmið Seðlabanka Íslands.`;
};

// Add deprecated annotation to this function when it is no longer used

/**
 * @deprecated Use interestSummaryDetails instead
 */
export const interestSummaryText = (months: number): string => `Vaxtatekjur síðustu ${months} mánuði`;

/**
 * @deprecated Use interestSummaryDetails instead
 */
export const interestAndIndexationSummaryText = (months: number): string =>
  `Vaxtatekjur og verðbætur síðustu ${months} mánuði`;
export const interestSummaryTextDetail = (months: number, hasIndexedAccount: boolean): string =>
  `Hér sérðu yfirlit yfir allar vaxtatekjur${
    hasIndexedAccount ? ' og verðbætur' : ''
  } sem þú hefur fengið frá Auði síðustu ${months} mánuði.`;
export const monthsString = (months: number): string => (months % 10 === 1 && months !== 11 ? 'mánuður' : 'mánuðir');
export const openExternalBankToSetUpTransfers = (bankName: string): string =>
  `Opnaðu vef ${bankName} til að millifæra eða setja upp reglulegar millifærslur.`;

export const getSignDocumentInfo = (phone: string): string => {
  return `Vinsamlegast fylgist með boðum sem koma í síma ${phone}`;
};

export const latest200TransactionsText = (oldestDate: string, newestDate: string): string => {
  return `Síðustu 200 færslur | Tímabil ${oldestDate} - ${newestDate}`;
};

export const latestTransactionsText = (oldestDate: string, newestDate: string): string => {
  return `Síðustu færslur | Tímabil ${oldestDate} - ${newestDate}`;
};

export const getSliderYearsAndMonthsSuffix = (months: number): string => {
  if (months < 12) {
    return monthsString(months);
  }
  const remainder = months % 12;
  const andMonthsText = `${remainder} ${monthsString(remainder)}`;
  return `${monthsString(months)} (${Math.floor(months / 12)} ár og ${andMonthsText})`;
};

/**
 * Can handle any number of termProducts,
 * i.e. will be "í 12 mánuði", "í 6 eða 12 mánuði", "í 3, 6 eða 12 mánuði", "í 2, 3, 6 eða 12 mánuði", etc.
 * Falls back to generic text if months array is empty.
 * @param minInterest Interest rate for shortest timespan
 * @param maxInterest Interest rate for longest timespan
 * @param months Array of months (i.e. [3, 6])
 * @returns The term deposit info text
 */
export const getTermDepositInfoText = (minInterest: number, maxInterest: number, months: Array<number>): string => {
  // Fallback if no months provided
  if (months.length === 0) {
    return TermAccountStrings.TermAccountSubHeader;
  }
  const monthsString = months.join(', ').replace(/,([^,]*)$/, ' eða$1');
  return `Þú getur bundið peninga í ${monthsString} mánuði og fengið frá ${toPercentageString(
    minInterest
  )} til ${toPercentageString(maxInterest)} vexti á ársgrundvelli.`;
};

export const getAddChildInsuranceInfoText = (quote: number): string => {
  return `Viðskiptavinum Auðar býðst barnatrygging TM á 20% afslætti, einungis ${toIskCurrencyString(
    quote,
    true
  )} á mánuði. Tryggingin býður betri örorkubætur ef slys eða sjúkdóma ber að garði.`;
};

export const getChildInsuranceAdText = (quote: number): string => {
  return `Besta fáanlega tryggingin fyrir barnið þitt á aðeins ${toIskCurrencyString(quote, true)} á mánuði.`;
};

/**
 * Returns "headline" text for accounts,
 * denoting how many days are left of the account lifetime
 * @param date The end date
 * @returns string
 */

export const getDaysLeftHeadline = (date: string): string => {
  const days = differenceInDays(new Date(date), new Date());
  return daysText(days);
};

export const getTermClosedHeadline = (date: string) => {
  const formattedDate = new Date(date).toLocaleDateString('is', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return `Bundinn til ${formattedDate}`;
};

/**
 * Returns an info type based on the external type passed, this is mainly for displaying the
 * start saving card with the customer bank info
 * @param bank External Bank type
 */
export const getBankInfo = (bank: ExternalBank): ExternalBankInfo => {
  switch (bank) {
    case ExternalBank.Landsbankinn: {
      return {
        bankName: 'Landsbankinn',
        bankNameInSentence: 'Landsbankans',
        bankUrl: 'https://netbanki.landsbankinn.is',
      };
    }
    case ExternalBank.ArionBanki: {
      return {
        bankName: 'Arion banki',
        bankNameInSentence: 'Arion banka',
        bankUrl: 'https://netbanki.arionbanki.is',
      };
    }
    case ExternalBank.IslandsBanki: {
      return {
        bankName: 'Íslandsbanki',
        bankNameInSentence: 'Íslandsbanka',
        bankUrl: 'https://www.isb.is',
      };
    }
    case ExternalBank.Indo: {
      return {
        bankName: 'Indó',
        bankNameInSentence: 'Indó',
        bankUrl: 'https://www.indo.is',
      };
    }
    case ExternalBank.Sparisjodir: {
      return {
        bankName: 'Sparisjóðurinn',
        bankNameInSentence: 'Sparisjóðsins',
        bankUrl: 'https://www.spar.is/is/einstaklingar/thjonusta/heimabanki',
      };
    }
    case ExternalBank.Kvika: {
      return {
        bankName: 'Kvika',
        bankNameInSentence: 'Kviku',
        bankUrl: 'https://netbanki.kvika.is',
      };
    }
    default: {
      return {
        bankName: '',
        bankNameInSentence: '',
        bankUrl: '',
      };
    }
  }
};

/**
 * Returns "[number] dagar/dagur síðan/eftir"
 * @param days
 */
export const daysText = (days: number): string => {
  const isNegative = days < 0;
  const normalizedDays = isNegative ? days * -1 : days;
  const isSingular = normalizedDays % 10 === 1 && normalizedDays % 100 !== 11;
  const dayString = isSingular ? 'dagur' : 'dagar';
  return `${normalizedDays} ${dayString} ${isNegative ? 'síðan' : 'eftir'}`;
};

/**
 * Returns "Þú getur sett þér markmið, búið til óbundinn reikning og lagt til hliðar og fengið [interests]% vexti!"
 * @param interests
 */
export const getMultipleSavingsCardDescription = (interests: number): string => {
  const formattedInterests = toPercentageString(interests, 2);
  return `Þú getur sett þér markmið, búið til óbundinn reikning og lagt til hliðar og fengið ${formattedInterests} vexti!`;
};

/**
 * Returns "Þú getur bundið peninga og fengið [interests] verðtryggða vexti og beðið um úttekt með 90 daga fyrirvara"
 * @param interests
 */
export const getIndexedSavingsCardDescription = (interests: number): string => {
  const formattedInterests = toPercentageString(interests, 2);
  return `Þú getur bundið peninga og fengið ${formattedInterests} verðtryggða vexti og beðið um úttekt með 90 daga fyrirvara`;
};

/**
 * getAddressString: returns content AddressSchema as string
 * @param AddressSchema
 * @returns string
 */
export const getAddressString = (addressInfo?: AddressSchema) => {
  const address = addressInfo?.address ?? '';
  const postalCode = addressInfo?.postalCode ?? '';
  const city = addressInfo?.city ?? '';
  const country = addressInfo?.country ?? '';
  return `${address}, ${postalCode} ${city}, ${country}`;
};

export const getAccountDisplayName = (account: AccountSchema, isChild?: boolean) => {
  if (getIsSavings(account)) {
    if (!account.isOriginalSavingsAccount && account.name) {
      return account.name;
    }
    return GeneralStrings.SavingsAccount;
  }
  if (getIsFutureAccount(account)) {
    if (isChild) {
      return account.name ?? FutureAccountStrings.FutureAccount;
    }
    const youngestUser = getYoungestUser(account);
    return youngestUser?.fullName ?? FutureAccountStrings.FutureAccount;
  }
  if (getIsIndexedSavings(account)) {
    return account.name ?? IndexedSavingsAccountsStrings.IndexedSavingsAccount;
  }
  if (getIsTermDeposit(account) && account.endsAt) {
    return account.open ? getDaysLeftHeadline(account.endsAt) : getTermClosedHeadline(account.endsAt);
  }

  return GeneralStrings.AccountOverview;
};
