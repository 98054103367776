export enum ErrorDescription {
  AMLError = 'AML Response error',
  AccountValidationError = 'Error in account validation',
  CountriesError = 'Error getting Countries',
  CreateTermDepositAccountError = 'Error when creating a Term Deposit Account',
  DebugDepositError = 'Debug deposit error',
  DebugDepositStatusCodeError = 'Debug deposit status code error',
  DebugPushNotificationError = 'Debug push notification error',
  GetAccountByIdError = 'GetAccountById error',
  GetAccountsError = 'Error in fetching Accounts',
  GetClosedAndDeletedAccounts = 'Error in fetching closed and deleted Accounts',
  GetProductsError = 'Error in fetching Products',
  GetTransactionsError = 'Error in fetching Transactions',
  InterestSummaryError = 'Error getting Interest Summary',
  LoginError = 'Login error',
  LogoutError = 'Logout user failed',
  LogoutStatusError = 'Logout status not 200',
  PropertyNotFound = 'XX not found in response',
  RegisterUserError = 'Register user error, response does not contain isPEP',
  ResetUserError = 'ResetUser Error',
  ResetCompanyError = 'ResetUser Error',
  ResetUserStatusCodeError = 'ResetUser StatusCode Error',
  ResetCompanyStatusCodeError = 'ResetCompany StatusCode Error',
  ResetChildInsuranceError = 'Reset child insurance error',
  ResetChildInsuranceStatusCodeError = 'Reset child insurance status code error',
  SignDocumentError = 'Not able to sign attorney of power document',
  SignDocumentStatusCodeError = 'SignDocument status code not 200',
  TransactionsError = 'Transactions have an invalid response, not a valid array',
  TransferError = 'Transfer response is invalid',
  UpdateUserError = 'Update user failed',
  UserProfileError = 'User profile error',
  UsersChildrenError = "Error in fetching user's children",
  CreatePEPChildDocumentError = 'Create PEP child document error',
  CreateFutureAccountError = 'Create future account error',
  GetUsersCurrentSessionError = "Error when getting user's current session",
  CreateChildInsuranceQuotePackageError = 'Error when creating child insurance quote package',
  CheckoutChildInsuranceQuotePackageError = 'Child insurance quote package checkout error',
  GetChildInsuranceStatusError = 'Error when getting child insurance quote status',
  UpdateAddressError = 'Error when updating users address',
  GetHistoricalInterestRatesError = 'Error when getting historical interest rates',
  ResetRecurringAMLError = 'Error when resetting recurring AML',
  ResetRecurringAMLStatusCodeError = 'ResetRecurringAML status code error',
  PostStartLoginError = 'Post start login error',
  PutLoginStatusError = 'Put login status error',
  PostDocumentSignatureError = 'Post document signature error',
  GetDocumentSignatureStatusError = 'Get document signature status error',
  DebugCreateClosedTermDepositAccountError = 'Debug create closed term deposit account error',
  CreateSavingsAccountError = 'Create savings account error',
  PutSavingsAccountError = 'Put savings account error',
  DeleteSavingsAccountError = 'Delete savings account error',
  DeleteSavingsAccountStatusError = 'Delete savings account status not 200',
  CreateIndexedSavingsAccountError = 'Create indexed savings account error',
  GetNoticesError = 'Get notices error',
  PostNoticeError = 'Post notices error',
  DeleteNoticeError = 'Delete notice error',
  PutIndexedSavingsAccountError = 'Put indexed savings account error',
  GetUserError = 'Get user error',
  GetSharedAccountsError = 'Get shared accounts error',
  PostSharedAccountsError = 'Post shared accounts error',
  GetSharedAccountsSummaryError = 'Get shared accounts summary error',
  UpdateAccountVisibilityError = 'Update account visibility error',
  GetUserAccessError = 'Error getting user access',
  SwitchUserAccessError = 'Error switching user access',
  GetCompanyRegistrationError = 'Error getting company registration',
  UpdateCompanyRegistrationError = 'Error updating company registration',
  UpdateCompanyError = 'Error updating company',
}
