import {
  AccountSchema,
  ChildSchema,
  CreateNoticeSchema,
  CreateTransactionSchema,
  AudurCompanySchema,
  GetUsersProfileResponseSchema,
  LoginResponseSchema,
  ProductSchema,
  UserAccessSchema,
} from '@kvika/audur-api-types';
import AudurWebApiClient from '../api/AudurWebApiClient';
import {
  AppState,
  ApiErrorWrapper,
  PrismicData,
  RegistrationInfoItem,
  ActionMap,
  UserWithouthAuthToken,
  ServiceStatusMode,
} from '../utils/types/Types';
import { IYearInterestSummary } from '../utils/types/APITypes';
import { setSentryUser } from '../utils/SentryWrapper';
import { initialState } from './AppContext';
import {
  removeSessionStorageItem,
  SessionStorageKeys,
  setSessionStorageItem,
} from '../utils/browserStorage/SessionStorage';
import {
  AudurMarketInterestRatesSavingsMarketInterestRates,
  AudurMarketInterestRatesTermDepositMarketInterestRates,
} from '../prismic/PrismicTypes';

export enum ActionType {
  UpdateApiClient = 'UPDATE_API_CLIENT',
  UpdateAccounts = 'UPDATE_ACCOUNTS',
  UpdateClosedAccounts = 'UPDATE_CLOSED_ACCOUNTS',
  AddAccount = 'ADD_ACCOUNT',
  UpdateInterestSummary = 'UPDATE_INTEREST_SUMMARY',
  UpdateProducts = 'UPDATE_PRODUCTS',
  UpdateApiError = 'UPDATE_API_ERROR',
  UpdateRegistrationInfo = 'UPDATE_REGISTRATION_INFO',
  UpdatePrismicSavingsMarketRates = 'UPDATE_PRISMIC_SAVINGS_MARKET_RATES',
  UpdatePrismicTermDepositMarketRates = 'UPDATE_PRISMIC_TERM_DEPOSIT_MARKET_RATES',
  UpdateUsersChildren = 'UPDATE_USERS_CHILDREN',
  UpdatePrismicData = 'UPDATE_PRISMIC_DATA',
  UpdateChild = 'UPDATE_CHILD',
  UpdateMaintenanceMode = 'UPDATE_MAINTENANCE_MODE',
  UpdateAccountBalance = 'UPDATE_ACCOUNT_BALANCE',
  UpdateLoginResponse = 'UPDATE_LOGIN_RESPONSE',
  UpdateUserProfile = 'UPDATE_USER_PROFILE',
  UpdatePhoneNumber = 'UPDATE_PHONE_NUMBER',
  LogoutUser = 'LOGOUT_USER',
  UpdateServiceStatusMode = 'UPDATE_SERVICE_STATUS_MODE',
  ShowChildrensAccounts = 'SHOW_CHILDRENS_ACCOUNTS',
  ShowChildBecameAdult = 'SHOW_CHILD_BECAME_ADULT',
  UpdateAccountAvailableAmount = 'UPDATE_ACCOUNT_AVAILABLE_AMOUNT',
  UpdateCompany = 'UPDATE_COMPANY',
  ResetCompany = 'RESET_COMPANY',
  UpdateAccess = 'UPDATE_ACCESS',
}

type AppPayload = {
  [ActionType.UpdateApiClient]: AudurWebApiClient;
  [ActionType.UpdateAccounts]: Array<AccountSchema>;
  [ActionType.UpdateClosedAccounts]: Array<AccountSchema>;
  [ActionType.AddAccount]: AccountSchema;
  [ActionType.UpdateInterestSummary]: IYearInterestSummary;
  [ActionType.UpdateProducts]: Array<ProductSchema>;
  [ActionType.UpdateApiError]: ApiErrorWrapper;
  [ActionType.UpdateRegistrationInfo]: RegistrationInfoItem;
  [ActionType.UpdatePrismicSavingsMarketRates]: AudurMarketInterestRatesSavingsMarketInterestRates;
  [ActionType.UpdatePrismicTermDepositMarketRates]: AudurMarketInterestRatesTermDepositMarketInterestRates;
  [ActionType.UpdateUsersChildren]: ChildSchema[] | undefined;
  [ActionType.UpdatePrismicData]: PrismicData;
  [ActionType.UpdateChild]: ChildSchema;
  [ActionType.UpdateServiceStatusMode]: ServiceStatusMode;
  [ActionType.UpdateAccountBalance]: CreateTransactionSchema;
  [ActionType.ShowChildBecameAdult]: boolean;
  [ActionType.UpdateLoginResponse]: LoginResponseSchema | undefined;
  [ActionType.UpdateUserProfile]: GetUsersProfileResponseSchema | undefined;
  [ActionType.UpdatePhoneNumber]: string | undefined;
  [ActionType.ShowChildrensAccounts]: boolean;
  [ActionType.LogoutUser]: undefined;
  [ActionType.UpdateAccountAvailableAmount]: CreateNoticeSchema;
  [ActionType.UpdateCompany]: AudurCompanySchema;
  [ActionType.ResetCompany]: undefined;
  [ActionType.UpdateAccess]: UserAccessSchema;
};

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

export const appReducer = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case ActionType.UpdateApiClient: {
      return {
        ...state,
        apiClient: action.payload,
      };
    }

    case ActionType.UpdateAccounts: {
      return {
        ...state,
        accounts: action.payload,
        loading: {
          ...state.loading,
          isLoadingAccounts: action.payload.length === 0,
        },
      };
    }

    case ActionType.AddAccount: {
      const newAccount = action.payload;

      /**
       * The interestEarned of the account that the backend returns in the response of creating a new account
       * is the calculated projected interestEarned at the end of the term.
       * The actual interestEarned we want to display is 0 since the account just got created.
       */
      return {
        ...state,
        accounts: [...state.accounts, { ...newAccount, interestEarned: 0 }],
      };
    }

    case ActionType.UpdateInterestSummary: {
      return {
        ...state,
        interestSummary: action.payload,
        loading: {
          ...state.loading,
          isLoadingInterestSummary: action.payload === null,
        },
      };
    }

    case ActionType.UpdateProducts: {
      return {
        ...state,
        products: action.payload,
        loading: {
          ...state.loading,
          isLoadingProducts: action.payload.length === 0,
        },
      };
    }

    case ActionType.UpdateClosedAccounts: {
      return {
        ...state,
        closedAccounts: action.payload,
      };
    }

    case ActionType.UpdateApiError: {
      return {
        ...state,
        audurError: action.payload,
      };
    }

    case ActionType.UpdateLoginResponse: {
      const loginResponse = action.payload;

      if (loginResponse) {
        setSentryUser(loginResponse.externalId, loginResponse.email ?? '');
      }

      const getUserWithoutAuthToken = (): UserWithouthAuthToken | undefined => {
        if (loginResponse && 'authorizationToken' in loginResponse) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { authorizationToken, ...userWithoutAuthToken } = loginResponse;
          return userWithoutAuthToken;
        }
        return loginResponse;
      };
      const userWithoutAuthToken = getUserWithoutAuthToken();
      if (userWithoutAuthToken) {
        // Store user object in session storage so refreshing the page works
        setSessionStorageItem(SessionStorageKeys.AUDUR_USER, JSON.stringify(userWithoutAuthToken));
      }
      return {
        ...state,
        user: loginResponse,
      };
    }

    case ActionType.UpdateUserProfile: {
      return {
        ...state,
        userProfile: action.payload,
      };
    }
    case ActionType.UpdatePhoneNumber: {
      return {
        ...state,
        phoneNumber: action.payload,
      };
    }

    case ActionType.UpdateRegistrationInfo: {
      return {
        ...state,
        registrationInfo: {
          ...state.registrationInfo,
          ...action.payload,
        },
      };
    }

    case ActionType.UpdatePrismicData: {
      return {
        ...state,
        prismicData: action.payload,
      };
    }

    case ActionType.UpdateUsersChildren: {
      return {
        ...state,
        usersChildren: action.payload,
      };
    }

    case ActionType.UpdateServiceStatusMode: {
      const { showAfter: prevShowAfter } = state.serviceStatusMode;
      const { showAfter } = action.payload;
      // Only update showAfter if the old showAfter timestamp has passed
      const updatedShowAfter = showAfter && prevShowAfter && showAfter < prevShowAfter ? prevShowAfter : showAfter;
      return {
        ...state,
        serviceStatusMode: {
          ...state.serviceStatusMode,
          ...action.payload,
          showAfter: updatedShowAfter,
        },
      };
    }

    case ActionType.UpdateAccountBalance: {
      const { amount, destinationAccountId, sourceAccountId } = action.payload;

      const absoluteAmount = Math.abs(amount);
      const updatedAccounts = state.accounts.map((account) => {
        if (sourceAccountId && account.id === sourceAccountId) {
          return { ...account, balance: account.balance - absoluteAmount };
        }
        if (destinationAccountId && account.id === destinationAccountId) {
          return { ...account, balance: account.balance + absoluteAmount };
        }
        return account;
      });

      return {
        ...state,
        accounts: updatedAccounts,
      };
    }
    case ActionType.LogoutUser: {
      removeSessionStorageItem(SessionStorageKeys.AUDUR_USER);
      removeSessionStorageItem(SessionStorageKeys.USER_ACCESS);
      // Don't reset the error as otherwise we won't see the error modal
      // when we're logged out after receiving a 401 error (when token has expired)
      return {
        ...initialState,
        user: undefined,
        userProfile: undefined,
        phoneNumber: undefined,
        audurError: state.audurError,
      };
    }

    case ActionType.ShowChildrensAccounts: {
      return {
        ...state,
        showChildrensAccounts: action.payload,
      };
    }

    case ActionType.ShowChildBecameAdult: {
      return {
        ...state,
        showChildBecameAdult: action.payload,
      };
    }

    case ActionType.UpdateAccountAvailableAmount: {
      const { amount, sourceAccountId } = action.payload;

      const updatedAccounts = state.accounts.map((account) => {
        if (account.id === sourceAccountId && account.noticesAvailableAmount !== undefined) {
          return {
            ...account,
            noticesAvailableAmount: account.noticesAvailableAmount ? account.noticesAvailableAmount + amount : amount,
          };
        }
        return account;
      });
      return {
        ...state,
        accounts: updatedAccounts,
      };
    }

    case ActionType.UpdateCompany: {
      return {
        ...state,
        companyInfo: action.payload,
        isCompanyEntity: true,
      };
    }

    case ActionType.ResetCompany: {
      return {
        ...state,
        companyInfo: undefined,
        isCompanyEntity: false,
        access: undefined,
      };
    }

    case ActionType.UpdateAccess: {
      if (action.payload) {
        setSessionStorageItem(SessionStorageKeys.USER_ACCESS, JSON.stringify(action.payload));
      } else {
        removeSessionStorageItem(SessionStorageKeys.USER_ACCESS);
      }
      return {
        ...state,
        access: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
