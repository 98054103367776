/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext } from 'react';
import { DocumentSignatureSchema } from '@kvika/api-types';

export type KvikaDocumentContext = {
  postDocumentSignature(documentId: number): Promise<DocumentSignatureSchema>;
  getDocumentSigningStatus(documentId: number, signatureId: number): Promise<DocumentSignatureSchema>;
  handleDocumentSigningStatus: (
    response: DocumentSignatureSchema,
    signatureId: number,
    setVerificationCode: (code: string) => void,
    handleDocumentStatusError: (errorText: string) => void,
    handleDocumentStatusSigned: () => void,
    onGetDocumentStatusRetry: (signatureId: number, finalPollingTime: Date) => void,
    finalPollingTime: Date
  ) => void;
};

const initialState = {
  postDocumentSignature: () => Promise.resolve({} as any),
  setTimeoutForPolling: () => null,
  getDocumentSigningStatus: () => Promise.resolve({} as any),
  handleDocumentSigningStatus: () => null,
};

export const KvikaDocumentSigningContext = createContext<KvikaDocumentContext>(initialState);

export const useKvikaDocumentSigningContext = (): KvikaDocumentContext => useContext(KvikaDocumentSigningContext);
