import styled from 'styled-components';
import Link from 'next/link';

import { Colors } from '../../styles/Styleguide';

export const StyledNavContainer = styled.nav`
  box-sizing: border-box;
  height: 80px;
  border-bottom: 1px solid ${Colors.LightCardGray};
  display: grid;
  align-items: center;
  grid-template-columns: auto 48px 48px;
  padding: 9px 32px;

  &:has(#back-button) {
    grid-template-columns: 48px auto 48px 48px;
    padding: 9px 32px;
    & > a {
      margin-left: 32px;
    }
  }

  @media all and (max-width: 45em) {
    grid-template-columns: auto 48px 48px !important;
    padding: 9px 8px !important;
    #back-button {
      display: none;
    }
  }
`;

export const StyledLink = styled(Link)`
  width: fit-content;
  cursor: pointer;
`;
