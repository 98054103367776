import Button from '@kvika/audur-button';
import { ArrowLeft, Logout, Settings } from '@kvika/audur-icons';
import { useRouter } from 'next/router';

import { AudurLogo } from 'components/Icons';
import { useAppContext } from '../../store/AppContext';
import { useLogin } from '../../utils/hooks/useLogin';
import { Links, pathsWithBackButton, Route } from '../../utils/navigation/navigation';
import { StyledLink, StyledNavContainer } from './NavigationStyles';

const Navigation = () => {
  const router = useRouter();
  const { handleLogout } = useLogin();
  const { state } = useAppContext();
  const { accountId } = router.query;
  const { closedAccounts } = state;

  const showBackButton = pathsWithBackButton.includes(router.pathname as Route);

  const handleSettingsClick = () => {
    router.push(Links.Settings);
  };

  const handleBackClick = () => {
    const isOnClosedAccountPage =
      router.pathname === Links.Transactions && !!closedAccounts.find((account) => account.id === Number(accountId));
    router.push(isOnClosedAccountPage ? Links.Settings : Links.Home);
  };

  return (
    <StyledNavContainer>
      {showBackButton && (
        <Button onClick={handleBackClick} variant="iconButtonSubtle" size="medium" id="back-button">
          <ArrowLeft />
        </Button>
      )}
      <StyledLink href={Links.Home}>
        <AudurLogo width={123} height={62} />
      </StyledLink>
      <Button onClick={handleSettingsClick} variant="iconButtonSubtle" size="medium">
        <Settings />
      </Button>
      <Button onClick={handleLogout} variant="iconButtonSubtle" size="medium">
        <Logout />
      </Button>
    </StyledNavContainer>
  );
};

export default Navigation;
