import { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { HttpDetailedExceptionResponse, HttpExceptionResponse, HTTPValidationError } from '@kvika/audur-api-types';

type AxiosErrorToJSON = () => {
  message: string;
  name: string;
  stack: string;
  config: AxiosRequestConfig;
  code?: string;
};

type ErrorResponseData = (HttpExceptionResponse | HttpDetailedExceptionResponse | HTTPValidationError) &
  Partial<Omit<HttpDetailedExceptionResponse, 'detail'>>;

export type AudurAxiosError = AxiosError<ErrorResponseData>;

export default class AudurApiError extends Error implements AudurAxiosError {
  cause?: Error | undefined;

  config?: InternalAxiosRequestConfig;

  code?: string;

  request?: unknown;

  response?: AxiosResponse<ErrorResponseData>;

  isAxiosError: boolean;

  toJSON: AxiosErrorToJSON;

  constructor(message: string, error: AudurAxiosError) {
    super(message);
    this.config = error.config;
    this.code = error.code;
    this.request = error.request;
    this.response = error.response;
    this.isAxiosError = error.isAxiosError;
    this.toJSON = error.toJSON as AxiosErrorToJSON;
  }
}
