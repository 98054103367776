import { decodeBase64String } from '@kvika/string-utils';
import lottie from 'lottie-web/build/player/lottie_light';
import { useEffect } from 'react';
import { ErrorStrings, GeneralStrings } from 'utils/strings';

import { AudurLogo } from 'components/Icons';
import { useAppContext } from '../../../store/AppContext';
import { StyledPEPImage } from '../../../styles/PageStyles/PageImageStyles';
import {
  StyledContainer,
  StyledContent,
  StyledImageContainer,
  StyledMessage,
  StyledTitle,
} from './MaintenanceModeStyles';

const MaintenanceMode = () => {
  const { state } = useAppContext();
  const { serviceStatusMode } = state;

  const message = serviceStatusMode.message
    ? decodeBase64String(serviceStatusMode.message)
    : ErrorStrings.ErrorHeadline;

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#hourglass') as Element,
      path: '/lottie/hourglass.json',
    });
  }, []);

  return (
    <StyledContainer>
      <StyledContent>
        <div>
          <AudurLogo width={130} height={100} />
          <StyledTitle>{GeneralStrings.SorryForTheTrouble}</StyledTitle>
          <StyledMessage>{message}</StyledMessage>
        </div>
        <StyledImageContainer>
          <StyledPEPImage id="hourglass" />
        </StyledImageContainer>
      </StyledContent>
    </StyledContainer>
  );
};

export default MaintenanceMode;
