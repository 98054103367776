const CalculatorSvg = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="calculator">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3C5.44772 3 5 3.44772 5 4V20C5 20.5523 5.44771 21 6 21H18C18.5523 21 19 20.5523 19 20V4C19 3.44772 18.5523 3 18 3H6ZM3 4C3 2.34315 4.34315 1 6 1H18C19.6569 1 21 2.34315 21 4V20C21 21.6569 19.6569 23 18 23H6C4.34315 23 3 21.6569 3 20V4ZM7 6C7 5.44772 7.44772 5 8 5H16C16.5523 5 17 5.44772 17 6C17 6.55228 16.5523 7 16 7H8C7.44772 7 7 6.55228 7 6ZM7 10C7 9.44772 7.44772 9 8 9H8.01C8.56228 9 9.01 9.44772 9.01 10C9.01 10.5523 8.56228 11 8.01 11H8C7.44772 11 7 10.5523 7 10ZM11 10C11 9.44772 11.4477 9 12 9H12.01C12.5623 9 13.01 9.44772 13.01 10C13.01 10.5523 12.5623 11 12.01 11H12C11.4477 11 11 10.5523 11 10ZM15 10C15 9.44772 15.4477 9 16 9H16.01C16.5623 9 17.01 9.44772 17.01 10C17.01 10.5523 16.5623 11 16.01 11H16C15.4477 11 15 10.5523 15 10ZM7 14C7 13.4477 7.44772 13 8 13H8.01C8.56228 13 9.01 13.4477 9.01 14C9.01 14.5523 8.56228 15 8.01 15H8C7.44772 15 7 14.5523 7 14ZM11 14C11 13.4477 11.4477 13 12 13H12.01C12.5623 13 13.01 13.4477 13.01 14C13.01 14.5523 12.5623 15 12.01 15H12C11.4477 15 11 14.5523 11 14ZM16 13C16.5523 13 17 13.4477 17 14V18C17 18.5523 16.5523 19 16 19C15.4477 19 15 18.5523 15 18V14C15 13.4477 15.4477 13 16 13ZM7 18C7 17.4477 7.44772 17 8 17H8.01C8.56228 17 9.01 17.4477 9.01 18C9.01 18.5523 8.56228 19 8.01 19H8C7.44772 19 7 18.5523 7 18ZM11 18C11 17.4477 11.4477 17 12 17H12.01C12.5623 17 13.01 17.4477 13.01 18C13.01 18.5523 12.5623 19 12.01 19H12C11.4477 19 11 18.5523 11 18Z"
        fill="#F5F5F5"
      />
    </g>
  </svg>
);
export default CalculatorSvg;
