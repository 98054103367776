import styled from 'styled-components';
import { StyledPageContainer } from '../../../styles/PageStyles/PageStyles';
import { FontSizeRem, GridRem } from '../../../styles/Styleguide';

export const StyledContainer = styled(StyledPageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledTitle = styled.h1`
  margin-top: ${GridRem.Gridx64};
  color: #fff;
  font-size: ${FontSizeRem.XL};
`;

export const StyledMessage = styled.p`
  color: #fff;
  font-size: ${FontSizeRem.BodyLarge};
  max-width: 360px;
`;

export const StyledImageContainer = styled.div`
  min-width: 220px;
`;
